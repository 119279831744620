import { GoogleApiCodes } from '../../configs/constants';
import { isInObject } from './index';
import { AnyObject } from '../../types';

export type GoogleApiError = {
	code: GoogleApiCodes;
};

function isGoogleApiError(error: unknown): error is GoogleApiError {
	return !!error && isInObject(GoogleApiCodes, (error as AnyObject).code);
}

export default isGoogleApiError;
