import { cx } from '@linaria/core';
import { ProfileInfoContainer, LinkContainer } from './styles';

type Props = {
	text: string;
	onDeleteSubUser?: () => void;
};

function ProfileInfoWidget({ text, onDeleteSubUser }: Props) {
	if (!text) {
		return null;
	}

	return (
		<div className={cx(ProfileInfoContainer.base)}>
			<div>{text}</div>
			{onDeleteSubUser && (
				<div className={cx(LinkContainer.base)}>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a title="Profil löschen" id="deleteAccount" onClick={onDeleteSubUser}>
						Profil löschen
					</a>
				</div>
			)}
		</div>
	);
}

export default ProfileInfoWidget;
