import type { ReactNode } from 'react';
import { Box } from '../../app/styles';
import { Root } from './styles';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { useParams } from 'react-router-dom';
import { useTrackPageImpression } from '../../../services/tracking';
import AgeVerificationCompleted from './infos/AgeVerificationCompleted';
import AgeVerificationPinChanged from './infos/AgeVerificationPinChanged';
import AccountDeleted from './infos/AccountDeleted';
import ResetPasswordMailSent from './infos/ResetPasswordMailSent';
import PasswordChanged from './infos/PasswordChanged';
import ErrorLinkInvalid from './infos/ErrorLinkInvalid';
import ErrorDeviceAuthorization from './infos/ErrorDeviceAuthorization';
import DeviceAuthorizationCompleted from './infos/DeviceAuthorizationCompleted';
import { buildMainLink } from '../../../services/link';
import Redirect from '../../app/routes/Redirect';
import ProfileChanged from './infos/ProfileChanged';
import ProfileAdded from './infos/ProfileAdded';
import ProfileDeleted from './infos/ProfileDeleted';
import ZdfUser from './infos/ZdfUser';
import { cx } from '@linaria/core';

const INFOS: Record<INFO_TYPES, ReactNode> = {
	[INFO_TYPES.AGE_VERIFICATION_COMPLETED]: <AgeVerificationCompleted />,
	[INFO_TYPES.AGE_VERIFICATION_PIN_CHANGED]: <AgeVerificationPinChanged />,
	[INFO_TYPES.ACCOUNT_DELETED]: <AccountDeleted />,
	[INFO_TYPES.RESET_PASSWORD_MAIL_SENT]: <ResetPasswordMailSent />,
	[INFO_TYPES.PASSWORD_CHANGED]: <PasswordChanged />,
	[INFO_TYPES.DEVICE_AUTHORIZATION_COMPLETED]: <DeviceAuthorizationCompleted />,
	[INFO_TYPES.ERROR_LINK_INVALID]: <ErrorLinkInvalid />,
	[INFO_TYPES.ERROR_DEVICE_AUTHORIZATION]: <ErrorDeviceAuthorization />,
	[INFO_TYPES.PROFILE_ADDED]: <ProfileAdded />,
	[INFO_TYPES.PROFILE_CHANGED]: <ProfileChanged />,
	[INFO_TYPES.PROFILE_DELETED]: <ProfileDeleted />,
	[INFO_TYPES.ZDF_USER]: <ZdfUser />,
};

function InfoPage() {
	/** Variables */
	const { infoType } = useParams<{ infoType: INFO_TYPES }>();
	const info = infoType && infoType in INFOS ? INFOS[infoType] : undefined;

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return info ? (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>{info}</main>
		</div>
	) : (
		<Redirect to={buildMainLink(PAGE_TYPES.NOT_FOUND)} />
	);
}

export default InfoPage;
