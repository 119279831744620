import React, { ReactNode, useMemo } from 'react';
import { H3, H4, P } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

type SophoraParagraph = {
	type: string;
	text: string;
};
export type SophoraData = {
	headline?: string;
	teaserTextLong?: string;
	copytext?: Array<SophoraParagraph>;
	metaData?: Array<{ twitterImageSrc?: string }>;
};
export default function useParseSophoraCopyText(data?: SophoraData): ReactNode {
	return useMemo(() => {
		return data?.copytext?.map((paragraph, index) => {
			switch (paragraph.type) {
				case 'absatz':
					return (
						<p className={cx(P.base)} key={index}>
							{paragraph.text}
						</p>
					);

				case 'ueberschrifth2':
					return (
						<h2 className={cx(H3.base)} key={index}>
							{paragraph.text}
						</h2>
					);

				case 'ueberschrifth3':
					return (
						<h3 className={cx(H4.base)} key={index}>
							{paragraph.text}
						</h3>
					);

				default:
					return null;
			}
		});
	}, [data?.copytext]);
}
