import { InfoIcon } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';
import { InfoBox } from './styles';

type InfoBoxWidgetProps = {
	text: string;
};

function InfoBoxWidget({ text }: InfoBoxWidgetProps) {
	return (
		<div className={cx(InfoBox.base)}>
			<InfoIcon />
			<p className={cx(InfoBox.text)}>{text}</p>
		</div>
	);
}

export default InfoBoxWidget;
