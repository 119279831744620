import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';
import { IconWrapper, Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ContextMenuRoot } from '@ard-online/component-styleguide/dist/components/ContextMenu2/styles';

export const Root = {
	base: css`
		width: 100%;
		z-index: 9;
		background: rgb(var(--global-background));
		position: relative;
		padding: 1rem 2rem;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;

		${Media('max', 's')} {
			padding: 1rem;
		}

		.${ContextMenuRoot} {
			ul {
				li {
					.${ButtonRoot.base} .${IconWrapper.base} {
						width: 55px;
					}
				}
			}
		}
	`,
};

export const Navigation = {
	base: css`
		display: flex;
		align-items: center;
		min-width: 0;
		svg {
			fill: rgb(var(--global-text));
			height: 1.4rem;
		}
		button {
			color: rgb(var(--global-text));
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 10px;
			svg {
				width: 48px;
				height: 48px;
			}
		}
	`,
};

export const LogoWrapper = {
	base: css`
		display: flex;
		padding-right: 10px;

		svg {
			height: 52px;
		}
		path {
			color: rgb(var(--global-text)) !important;
			fill: rgb(var(--global-text)) !important;
		}
	`,
};
