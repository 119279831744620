import { useContextActions } from '../../../services/actions/ActionsService';
import { isGoogleApiError } from '../../../services/misc';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import userService from '../../../services/user/UserService';
import { ReactNode, useCallback, useRef } from 'react';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import env from '../../../configs/env';
import { PAGE_TYPES, MODE_TYPES } from '../../../configs/types';
import { buildMainLink } from '../../../services/link';

type SsoCallback = (redirectUrl?: string) => Promise<void>;

export function useSso(): { ssoForm: ReactNode; sso: SsoCallback } {
	const { appState } = useContextActions();
	const ssoFormRef = useRef<HTMLFormElement>(null);
	const idTokenInputRef = useRef<HTMLInputElement>(null);
	const refreshTokenInputRef = useRef<HTMLInputElement>(null);
	const redirectUrlInputRef = useRef<HTMLInputElement>(null);
	const { clientId, entryPageSlug, redirectUrl: redirectUrlFromAppState } = appState.navigation;
	const { modeType } = appState.config;

	const ssoForm = (
		<>
			<form action={`${env.HOST}/sso`} method="POST" ref={ssoFormRef}>
				<input type="hidden" name="idToken" ref={idTokenInputRef} />
				<input type="hidden" name="refreshToken" ref={refreshTokenInputRef} />
				<input type="hidden" name="redirectUrl" ref={redirectUrlInputRef} />
			</form>
		</>
	);

	const redirectUrl = new URL(env.HOST);

	if (modeType && modeType !== MODE_TYPES.STANDALONE) {
		redirectUrl.searchParams.set('modeType', modeType);
	}

	if (clientId) {
		redirectUrl.searchParams.set('clientId', clientId);
	}

	if (redirectUrlFromAppState) {
		redirectUrl.searchParams.set('redirect_uri', redirectUrlFromAppState);
	}

	const sso = useCallback(async (redirectPath?: string) => {
		if (
			idTokenInputRef.current &&
			refreshTokenInputRef.current &&
			redirectUrlInputRef.current &&
			ssoFormRef.current
		) {
			idTokenInputRef.current.value = String(userService.idToken);
			refreshTokenInputRef.current.value = String(userService.refreshToken);

			const isLoginConfirm = entryPageSlug === PAGE_TYPES.LOGIN_CONFIRM;

			if (
				// external login
				(redirectUrlFromAppState &&
					(entryPageSlug === PAGE_TYPES.START || // normal login
						entryPageSlug === PAGE_TYPES.REGISTRATION || // registration completed
						(entryPageSlug === PAGE_TYPES.AGE_VERIFICATION && userService.isAgeVerified))) || // is age verified
				isLoginConfirm // login with confirm
			) {
				if (isLoginConfirm) {
					redirectUrl.pathname = buildMainLink(entryPageSlug);
					redirectUrlInputRef.current.value = redirectUrl.href;
				} else {
					redirectUrlInputRef.current.value = redirectUrlFromAppState;
				}
			} else {
				// internal login
				if (redirectPath) {
					redirectUrlInputRef.current.value = env.HOST + redirectPath;
				} else {
					redirectUrl.pathname = buildMainLink(
						entryPageSlug === PAGE_TYPES.START ? PAGE_TYPES.EDIT_ACCOUNT : entryPageSlug,
					);
					redirectUrlInputRef.current.value = redirectUrl.href;
				}
			}

			ssoFormRef.current.submit();
		}
	}, []);

	return { ssoForm, sso };
}

type LoginCallback = (email: string, password: string) => Promise<void>;

export function useLoginWithEmailPassword(): LoginCallback {
	const { appState } = useContextActions();

	return useCallback(async (email: string, password: string) => {
		let user;

		try {
			user = await userService.loginWithPassword(email, password);
		} catch (error) {
			if (isGoogleApiError(error) && error.code === GoogleApiCodes.TOO_MANY_REQUESTS) {
				throw new Error(ErrorMessages.DEFAULT);
			} else {
				throw new Error(ErrorMessages.EMAIL_OR_PASSWORT);
			}
		}

		if (!user.emailVerified) {
			try {
				await sendEmailVerification(user, appState);
			} catch (error) {
				console.error(error);

				if (isGoogleApiError(error) && error.code === GoogleApiCodes.TOO_MANY_REQUESTS) {
					// no email was sent (rate limit: 120 requests/hour)
					throw new Error(ErrorMessages.VERIFY_EMAIL);
				} else {
					throw new Error(ErrorMessages.DEFAULT);
				}
			}
			throw new Error(ErrorMessages.VERIFY_EMAIL);
		}
	}, []);
}
