import { IDBPDatabase, openDB } from 'idb';
import { IDB_STORE_TYPES } from '../../configs/types';
import { DB_NAME, DB_VERSION } from '../../configs/constants';

let dbPromise: Promise<IDBPDatabase>;

try {
	dbPromise = openDB(DB_NAME, DB_VERSION, {
		upgrade(db) {
			if (!db.objectStoreNames.contains(IDB_STORE_TYPES.SETTINGS)) {
				db.createObjectStore(IDB_STORE_TYPES.SETTINGS);
			}
			if (!db.objectStoreNames.contains(IDB_STORE_TYPES.USER)) {
				db.createObjectStore(IDB_STORE_TYPES.USER);
			}
		},
	});
} catch (error) {
	console.error(error);
}

class AbstractStorageService<T> {
	protected storageName: string;
	protected items: Map<string, T> = new Map<string, T>();

	constructor(storageName: string) {
		this.storageName = storageName;
	}

	async removeItemFromIdb(key: string): Promise<void> {
		try {
			await (await dbPromise).delete(this.storageName, key);
		} catch (error) {
			console.error("Unable to use indexed DB 'delete'", error);
		}
	}

	async clearItemsFromIdb(): Promise<void> {
		try {
			await (await dbPromise).clear(this.storageName);
		} catch (error) {
			console.error("Unable to use indexed DB 'clear'", error);
		}
	}

	async clearItems() {
		this.items.clear();
		await this.clearItemsFromIdb();
	}
}

export class KeyValueStorageService<R> extends AbstractStorageService<R> {
	async init() {
		const mapFromIndexedDb = await this.getAllItemsFromIdb();
		if (mapFromIndexedDb.size) {
			for (const [key, val] of mapFromIndexedDb) {
				this.items.set(key, val);
			}
		}
	}

	addItem(key: string, val: R) {
		this.addItemToMemory(key, val);
		this.addItemToIdb(key, val);
	}

	addItemToMemory(key: string, val: R) {
		this.items.set(key, val);
	}

	async addItemToIdb(key: string, val: R): Promise<void> {
		try {
			await (await dbPromise).put(this.storageName, val, key);
		} catch (error) {
			console.error("Unable to use indexed DB 'put'", error);
		}
	}

	getItem(key: string): R | undefined {
		return this.items.get(key);
	}

	getAllItems(): Map<string, R> {
		return this.items;
	}

	async getAllItemsFromIdb(): Promise<Map<string, R>> {
		const storedItems = new Map();

		try {
			const db = await dbPromise;
			let cursor = await db.transaction(this.storageName).store.openCursor();
			while (cursor) {
				storedItems.set(String(cursor.key), cursor.value);
				cursor = await cursor.continue();
			}
		} catch (error) {
			console.error("Unable to use indexed DB 'transaction'", error);
		}

		return storedItems;
	}

	async clearItemsWithExcludes(whitelist: Array<string>) {
		if (this.items.size) {
			for (const [key] of this.items) {
				if (!whitelist.includes(key)) {
					this.items.delete(key);
					await super.removeItemFromIdb(key);
				}
			}
		}
	}
}
