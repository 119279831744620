import { css } from '@linaria/core';

export const ProfileInfoContainer = {
	base: css`
		display: flex;
		width: 100%;
		padding: 32px 16px;
		text-align: left;
		column-gap: 10px;
		margin: 10px 0 20px;
		box-sizing: border-box;
		border-top: 1px solid rgba(var(--global-text), 0.2);
		border-bottom: 1px solid rgba(var(--global-text), 0.2);
	`,
};
export const LinkContainer = {
	base: css`
		flex-shrink: 0;

		a {
			text-decoration: underline;
			&:hover {
				cursor: pointer;
			}
		}
	`,
};
