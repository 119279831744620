export default async function fetchCustomTokenByCookies(): Promise<string | undefined> {
	try {
		const res = await fetch(`/sso/token`, {
			credentials: 'include',
		});

		if (res.ok) {
			const { customToken } = await res.json();
			return customToken;
		} else {
			throw new Error(`Error fetching Custom Token | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		console.error(error);
	}
}
