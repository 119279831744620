import { css } from '@linaria/core';
import { Mask } from '../../app/styles';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { H2, P } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		max-height: 100%;
		.${Mask.base} {
			margin-bottom: 10px;
		}

		.${P.base} {
			.${ButtonRoot.base} {
				display: inline-block;
				font-size: 0.9rem;
				background: green;
			}
		}
	`,
};

export const SentMailAgainButtonWrapper = {
	base: css`
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		> p {
			flex-shrink: 0;
		}

		.${ButtonRoot.base} {
			border-radius: 0;
			> span {
				padding: 0;
				text-decoration: underline;
				font-weight: bold;
			}
			&:after {
				content: none;
			}
		}
	`,
};

export const ButtonsWrapper = {
	base: css`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		flex-wrap: wrap;
		> a {
			margin-bottom: 10px;
		}
	`,
};

export const ZdfInfo = {
	base: css`
		&& {
			.${H2.base} {
				font-weight: 600;
				text-align: left;
				padding: 0;
				font-size: 1.2rem;
			}
			.${P.base} {
				text-align: left;
				a:not([data-type]) {
					display: inline-block;
					text-decoration: underline;
				}
			}
		}
	`,
};
