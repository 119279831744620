import { useEffect, useState } from 'react';

export default function useFetch(url: string) {
	const [data, setData] = useState([]);

	async function fetchJSON(url: string) {
		try {
			const res = await fetch(url);

			if (!res.ok) {
				console.error(res.status, url);
				return;
			}

			const json = await res.json();

			setData(json);
		} catch (error) {
			console.error(error, url);
		}
	}

	useEffect(() => {
		fetchJSON(url);
	}, [url]);

	return data;
}
