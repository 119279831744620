import { useState } from 'react';
import { buildLogoutLink, buildMainLink } from '../../../services/link';
import { Headline, LoginButtonWrapper, Root } from './styles';
import { Button, P } from '@ard-online/component-styleguide';
import { Box, RowAction } from '../../app/styles';
import { redirectToRefererApp } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import env from '../../../configs/env';

function LoginConfirmPage() {
	/** Variables */
	const { appState } = useContextActions();
	const [email, setEmail] = useState(userService.email);

	async function redirectToReferrer() {
		await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.LOGGED_IN);
	}

	function logoutAndRedirectToLoginConfirmPage() {
		userService.email = null;
		setEmail(null);

		window.location.href = buildLogoutLink(`${env.HOST}${buildMainLink(PAGE_TYPES.LOGIN_CONFIRM)}`, appState);
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<h1 className={cx(Headline.base)}>{STRINGS.loginConfirmPage.headline}</h1>
				<p className={cx(P.base)}>{STRINGS.loginConfirmPage.kicker}</p>
				{userService.email && (
					<div className={cx(RowAction.base)}>
						<span>{email}</span>
						<Button
							type={BUTTON_TYPES.TEXT}
							text="Ändern"
							htmlAttributes={{ title: 'Andere E-Mail-Adresse eingeben', type: 'button' }}
							onClick={logoutAndRedirectToLoginConfirmPage}
						/>
					</div>
				)}
				<div className={cx(LoginButtonWrapper.base)}>
					<Button
						text={STRINGS.loginConfirmPage.button}
						type={BUTTON_TYPES.PRIMARY}
						onClick={redirectToReferrer}
					/>
				</div>
			</main>
		</div>
	);
}

export default LoginConfirmPage;
