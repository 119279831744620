// Fragen zu diesem Script an Johannes Schmid.
export type IdentityCardData = {
	country?: string;
	idNumber: string;
	birthday: string;
	validity: string;
	extra?: string;
	// see https://en.wikipedia.org/wiki/Machine-readable_passport, char pos. 29-43
	checkCode: string | null;
};
type ResultData = {
	isValid: boolean;
	birthday: string;
	validity: string;
};
const CHECK_FACTOR: number[] = [7, 3, 1];

/**
 * Convert a single line to a IIdentityCardData structure for non-44 character lines.
 * @param line the 2nd line of the id document.
 * @param country? the issuing country code.
 * @return the IdentityCardData structure.
 */
function backupConvertSingleLine(line: string, country?: string): IdentityCardData {
	const parts = line.split('<');
	const data: IdentityCardData = {
		country,
		idNumber: '',
		birthday: '',
		validity: '',
		checkCode: '',
	};

	for (let i = 0; i < parts.length; i++) {
		if (parts[i].length) {
			continue;
		}

		parts.splice(i, 1);
		i--;
	}

	if (!parts[0] || parts.length < 2) {
		throw new Error(`missing first part: ${line}`);
	}

	data.idNumber = parts[0];

	if (data.idNumber.length >= 20) {
		let i = 10;
		const chk = data.idNumber;

		while (i < 20 && chk.charCodeAt(i) > 0x40) {
			i++;
		}

		data.idNumber = chk.substring(0, i);
		parts[0] = chk.substring(i);
	} else {
		parts.splice(0, 1);

		if (data.idNumber.length <= 10 && parts[0].length && parts[0].charCodeAt(0) < 0x40) {
			// copy check code from next code to end of idNumber (a special format separates the id and check code with a "<<").
			data.idNumber += parts[0].substring(0, 1);
			parts[0] = parts[0].substring(1);
		}
	}

	while (data.idNumber.length && data.idNumber.charCodeAt(data.idNumber.length - 1) > 0x40) {
		data.idNumber = data.idNumber.substring(0, data.idNumber.length - 1);
	}

	let foundBirthday = false;

	for (let i = 0; i < parts.length - 1; i++) {
		let chk = parts[i];

		while (chk.length && chk.charCodeAt(0) > 0x40) {
			chk = chk.substring(1);
		}

		if (chk.length < 7) {
			continue;
		}

		if (chk.length > 7) {
			parts.splice(i + 1, 0, chk.substring(7));
			chk = chk.substring(0, 7);
		}

		if (foundBirthday) {
			data.validity = chk;
			break;
		}

		data.birthday = chk;
		foundBirthday = true;
	}

	if (!data.birthday) {
		throw new Error(`did not find birthday in line ${line}`);
	}

	if (!data.validity) {
		throw new Error(`did not find validity in line ${line}`);
	}

	data.checkCode = parts[parts.length - 1];

	if (data.checkCode.length !== 1) {
		if (data.checkCode.length === 2 && line.length === 44) {
			data.checkCode = data.checkCode.substring(1);
			data.extra = line.substring(28, 43);
		} else {
			throw new Error(`invalid check code in line ${line}`);
		}
	}

	return data;
}

/**
 * Convert a single line to a IIdentityCardData structure.
 * @param line the 2nd line of the id document.
 * @param country? the issuing country code.
 * @return the IIdentityCardData structure.
 */
export function convertSingleLine(line: string, country?: string): IdentityCardData {
	if (line.length !== 44 && line.length !== 36) {
		throw new Error(`invalid line length ${line.length} for line ${line}`);
	}

	// see https://en.wikipedia.org/wiki/Machine-readable_passport
	const data: IdentityCardData = {
		country,
		idNumber: line.substring(0, 10),
		birthday: line.substring(13, 20),
		validity: line.substring(21, 28),
		checkCode: line.substring(line.length - 1),
	};

	if (line.length === 36) {
		// Machine-readable visas, also refugee ID
		data.checkCode = null;
		data.extra = line.substring(28);
	} else {
		const extraCheck = line.substring(line.length - 2, line.length - 1);

		if (extraCheck !== '<') {
			data.extra = line.substring(28, line.length - 1);
		}
	}

	if (data.validity.indexOf('<') >= 0 || data.birthday.indexOf('<') >= 0) {
		return backupConvertSingleLine(line, country);
	}

	return data;
}

/**
 * Verify the identity card data.
 * @param data the IIdentityCardData input structure.
 * @return the validation result which is always valid, as for invalid data formats an exception is thrown.
 */
export function checkIdCardData(data: IdentityCardData): ResultData {
	if (data.idNumber.length < 4) {
		throw new Error('idNumber too short');
	}

	if (data.birthday.length !== 7) {
		throw new Error('birthday length invalid');
	}

	if (data.validity.length !== 7) {
		throw new Error('validity length invalid');
	}

	verifyCode(
		data.idNumber.substring(0, data.idNumber.length - 1),
		data.idNumber.substring(data.idNumber.length - 1),
		true,
	);
	verifyCode(data.birthday.substring(0, 6), data.birthday.substring(6), false);
	verifyCode(data.validity.substring(0, 6), data.validity.substring(6), false);
	let idNumber = data.idNumber;

	if (idNumber.length < 10) {
		// see: https://de.wikipedia.org/wiki/Identit%C3%A4tskarte_(Schweiz)
		const padded = `${idNumber.substring(0, idNumber.length - 1)}0000000000`.substring(0, 9);
		idNumber = `${padded}${idNumber.substring(data.idNumber.length - 1)}`;
	}

	let completeCode = `${idNumber}${data.birthday}${data.validity}`;

	if (data.extra) {
		completeCode += data.extra;
	}

	if (data.checkCode !== null) {
		verifyCode(completeCode, data.checkCode, true);
	}

	const birthday = convertDate(data.birthday.substring(0, 6), false);
	let validity = convertDate(data.validity.substring(0, 6), true);
	validity = new Date(validity.getTime() + 1000 * 60 * 60 * 24 - 1);
	const isValid: boolean = validity > new Date();
	return {
		isValid,
		birthday: birthday.toISOString(),
		validity: validity.toISOString(),
	};
}

/**
 * Convert an identity card date to ISO format.
 * @param str the date string (6 characters, format YYMMDD).
 * @param isValidity true for validity dates which can be in the future, false for birth dates which are always in the past.
 */
function convertDate(str: string, isValidity: boolean): Date {
	let year = parseInt(str.substring(0, 2), 10);
	const month = parseInt(str.substring(2, 4), 10);
	const day = parseInt(str.substring(4, 6), 10);

	if (isNaN(day) || day < 1 || day > 31) {
		throw new Error(`invalid day in date ${str}`);
	}

	if (isNaN(month) || month < 1 || month > 12) {
		throw new Error(`invalid month in date ${str}`);
	}

	if (isNaN(year) || year < 0 || year > 99) {
		throw new Error(`invalid month in date ${str}`);
	}

	const now = new Date();
	const currentYear = now.getFullYear() + (isValidity ? 30 : 0);

	if (year > currentYear % 100) {
		year -= 100;
	}

	year += currentYear - (currentYear % 100);
	return new Date(`${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T00:00:00.000Z`);
}

/**
 * Verify a check code.
 * @param str the input string to verify.
 * @param checkCode the check code.
 * @param allowAlpha true if input string may carry alpha (non-numeric) characters.
 */
function verifyCode(str: string, checkCode: string, allowAlpha: boolean): void {
	let sum = 0;

	// see ICAO 9303-3
	for (let i = 0; i < str.length; i++) {
		const c = str.substring(i, i + 1);

		if (c === '<') {
			continue;
		}

		let n = parseInt(c, 10);

		if (isNaN(n) || n < 0 || n > 9) {
			if (!allowAlpha) {
				throw new Error(`unexpected non-numeric character ${c} in verify input ${str}`);
			}

			const cc = c.toUpperCase().charCodeAt(0);

			if (cc >= 0x40 && cc <= 0x5a) {
				n = cc - 0x41 + 10;
			} else {
				throw new Error(`unexpected invalid alpha character ${c} in verify input ${str}`);
			}
		}

		const addCheck = (n * CHECK_FACTOR[i % CHECK_FACTOR.length]) % 10;
		sum += addCheck;
	}

	const expectCode = `${sum % 10}`;

	if (checkCode !== expectCode) {
		throw new Error(`invalid check code while verifying ${str}, expected ${expectCode} but got ${checkCode}`);
	}
}
