import { buildMainLink } from './index';
import env from '../../configs/env';
import { AppState } from '../../configs/defaultState';
import { MODE_TYPES } from '../../configs/types';

function buildLogoutLink(redirectUrl: string = env.HOST, appState?: AppState) {
	const redirectUri = new URL(redirectUrl);

	if (appState?.config.modeType && appState.config.modeType !== MODE_TYPES.STANDALONE) {
		redirectUri.searchParams.set('modeType', appState.config.modeType);
	}

	if (appState?.navigation.clientId) {
		redirectUri.searchParams.set('clientId', appState.navigation.clientId);
	}

	if (appState?.navigation.redirectUrl) {
		redirectUri.searchParams.set('redirect_uri', appState.navigation.redirectUrl);
	}

	return `${buildMainLink(`sso/logout`)}?redirect_uri=${encodeURIComponent(redirectUri.href)}`;
}

export default buildLogoutLink;
