import { FormEvent, useEffect, useState } from 'react';
import { Root } from './styles';
import { BackButtonWrapper, Box, Form, Mask, RowAction } from '../../app/styles';
import { BackIcon, Button, H1, P } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../../firebase';
import { isGoogleApiError } from '../../../services/misc';
import { Avatars, GoogleApiCodes } from '../../../configs/constants';
import PasswordWidget from '../../widgets/password/PasswordWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function RegistrationPasswordPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState('');

	const isNextStepAllowed = !!password;

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				const { user } = await createUserWithEmailAndPassword(auth, appState.registration.email, password);
				await updateProfile(user, {
					displayName: user.email?.split('@')[0],
					photoURL: Avatars[0],
				});
				navigate(`../${PAGE_TYPES.REGISTRATION_PROFILE}`);
			} catch (error) {
				console.error(error);

				if (isGoogleApiError(error)) {
					switch (error.code) {
						case GoogleApiCodes.EMAIL_NOT_VALID:
							setMessage('Die E-Mail-Adresse ist ungültig.');
							break;
						case GoogleApiCodes.EMAIL_ALREADY_IN_USE:
							setMessage('Das Benutzerkonto konnte leider nicht angelegt werden.');
							break;
					}
				} else {
					setMessage('Das Benutzerkonto konnte leider nicht angelegt werden.');
				}

				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!appState.registration.email) {
			navigate('..', { replace: true });
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<div className={cx(BackButtonWrapper.base)}>
						<Button
							text={STRINGS.registrationPasswordPage.back.button}
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							href={buildMainLink(PAGE_TYPES.START)}
							as={Link}
						/>
					</div>
					<p className={cx(P.base)}>Schritt 2 von 4</p>
					<h1 className={cx(H1.base)}>{STRINGS.registrationPasswordPage.step_2.headline}</h1>
					<div className={cx(Mask.base)}>
						<div className={cx(RowAction.base)}>
							<span>{appState.registration.email}</span>
							<Link to={'..'} title="Andere E-Mail-Adresse eingeben">
								{STRINGS.registrationPasswordPage.step_2.button}
							</Link>
						</div>
						<PasswordWidget onValidPassword={setPassword} />
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text="Weiter"
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!isNextStepAllowed}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default RegistrationPasswordPage;
