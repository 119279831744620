import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const ProfileImage = {
	base: css`
		position: relative;
	`,
};

export const ProfileImageContainer = {
	base: css`
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-top: 30px;
		width: 100%;

		.${ButtonRoot.base} {
			background: transparent !important;
			&:focus-visible {
				outline: 3px solid rgb(var(--brand-dark-primary));
			}
		}

		.${ImageRoot.base} {
			width: 48px;
			height: 48px;
			@media only screen and (min-width: 381px) {
				width: 64px;
				height: 64px;
			}
			@media only screen and (min-width: 520px) {
				width: 88px;
				height: 88px;
			}
		}
	`,
	small: css`
		.${ImageRoot.base} {
			width: 48px;
			height: 48px;
			@media only screen and (min-width: 381px) {
				width: 64px;
				height: 64px;
			}
		}
	`,
};
