import useUrlSearchParams from '../../../services/useUrlSearchParams';
import { ButtonWrapper } from '../../pages/login/styles';
import { Button } from '@ard-online/component-styleguide';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Link } from 'react-router-dom';
import { cx } from '@linaria/core';
import userService from '../../../services/user/UserService';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function CreateAccountButtonWidget() {
	const searchParams = useUrlSearchParams();
	const isEmailConfirmed = searchParams.has('emailConfirmed');

	return (
		<>
			{!userService.userId && !isEmailConfirmed && (
				<div className={cx(ButtonWrapper.base)}>
					<Button
						href={buildMainLink(PAGE_TYPES.REGISTRATION)}
						as={Link}
						text={STRINGS.createAccountButtonWidget.button}
						type={BUTTON_TYPES.SECONDARY}
					/>
				</div>
			)}
		</>
	);
}

export default CreateAccountButtonWidget;
