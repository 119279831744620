import userService from '../user/UserService';
import { User } from '../../types';

export default async function fetchUsers(): Promise<Array<User> | undefined> {
	try {
		const idToken = userService.idToken;
		const res = await fetch(`/users`, {
			method: 'GET',
			headers: {
				'X-Authorization': `Bearer ${idToken}`,
			},
		});

		if (res.ok) {
			return await res.json();
		} else {
			throw new Error(`Error fetching users | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		console.error(error);
	}
}
