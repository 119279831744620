//TODO: auslagern in MiscService
import { AnyObject } from '../../types';

export default function isTouchDevice(): boolean {
	const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

	const mq = function (query: string) {
		return window.matchMedia(query).matches;
	};

	if (
		'ontouchstart' in window ||
		((window as AnyObject).DocumentTouch && document instanceof (window as AnyObject).DocumentTouch)
	) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
	return mq(query);
}
