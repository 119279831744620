import { FormEvent, useEffect, useState } from 'react';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { BackIcon, Button, H1, Input, P } from '@ard-online/component-styleguide';
import { PinInputWrapper, Root } from './styles';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildAgeVerificationLink, buildInfoLink, buildMainLink } from '../../../services/link';
import MessageWidget from '../../widgets/message/MessageWidget';
import type { AnyFunction, AnyObject } from '../../../types';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { isGoogleApiError, isTouchDevice } from '../../../services/misc';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useTrackPageImpression } from '../../../services/tracking';

function AgeVerificationPinPage() {
	/** Variables */
	const { appState, setAgeVerificationIdCardData } = useContextActions();
	const navigate = useNavigate();
	const isChange = !!useMatch(buildAgeVerificationLink(PAGE_TYPES.AGE_VERIFICATION_PIN_CHANGE));
	const idCardData = appState.ageVerification.idCardData;
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);
	const [pin, setPin]: [string, AnyFunction] = useState('');
	const [password, setPassword]: [string, AnyFunction] = useState('');

	function isInputValid() {
		return pin?.length === 4 && password;
	}

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();
		setIsLoading(true);

		if (!isLoading) {
			try {
				await signInWithEmailAndPassword(auth, userService.email as string, password); // validate password
				const idToken = userService.idToken;
				const res = await fetch('/age-verification', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ ...idCardData, pin, idToken }),
				});

				if (res.ok) {
					await userService.refreshIdToken();
					setAgeVerificationIdCardData(null);
					const infoType = isChange
						? INFO_TYPES.AGE_VERIFICATION_PIN_CHANGED
						: INFO_TYPES.AGE_VERIFICATION_COMPLETED;
					navigate(buildInfoLink(infoType));
				} else {
					setIsLoading(false);
					setMessage(ErrorMessages.DEFAULT);
				}
			} catch (error) {
				setIsLoading(false);

				if (isGoogleApiError(error) && error.code === GoogleApiCodes.WRONG_PASSWORD) {
					setMessage('E-Mail-Adresse und Passwort stimmen nicht überein.');
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				console.error(error);
			}
		}
	}

	function onPinInputChange(e: AnyObject) {
		if (e.isValid) {
			setPin(e.value);
		} else {
			setPin('');
		}
	}

	function onPasswordInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!(idCardData && idCardData.idNumber && idCardData.birthday && idCardData.validity) && !isChange) {
			navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT), { replace: true });
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<div className={cx(BackButtonWrapper.base)}>
					<Button
						text="Zurück"
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						href={
							isChange
								? buildMainLink(PAGE_TYPES.EDIT_ACCOUNT)
								: `../${PAGE_TYPES.AGE_VERIFICATION_ID_CARD}`
						}
						as={Link}
					/>
				</div>
				{!isChange && <p className={cx(P.base)}>Schritt 2 von 2</p>}
				<h1 className={cx(H1.base)}>
					{isChange
						? STRINGS.ageVerificationPinPage.changeCode.update.headline
						: STRINGS.ageVerificationPinPage.changeCode.new.headline}
				</h1>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<div className={cx(Mask.base)}>
						<p className={cx(P.base)}>
							{isChange
								? STRINGS.ageVerificationPinPage.changeCode.update.text
								: STRINGS.ageVerificationPinPage.changeCode.new.text}
						</p>
						<div className={cx(PinInputWrapper.base)}>
							<Input
								type="pin"
								label="pin"
								isRequired
								errorMessage="Bitte nur Zahlen von 0 bis 9 eingeben"
								onChange={onPinInputChange}
								hasFocus={!isTouchDevice()}
								hasHintValidation
								htmlAttributes={{
									title: 'Jugendschutz-Code eingeben',
									placeholder: '*',
								}}
							/>
						</div>
						<p className={cx(P.base)}>
							Bestätigen Sie den Code mit Ihrem Passwort für {userService.email}.
						</p>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onPasswordInputChange}
							htmlAttributes={{
								autoComplete: 'current-password',
								inputMode: 'text',
								title: 'Passwort eingeben',
							}}
						/>
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text="Code festlegen"
						isDisabled={!isInputValid()}
						onClick={onSubmit}
						isLoading={isLoading}
						type={BUTTON_TYPES.PRIMARY}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default AgeVerificationPinPage;
