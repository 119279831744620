import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const Root = {
	base: css`
		max-height: 100%;
	`,
};

export const Approval = {
	base: css`
		margin: 15px 0 0 0;

		label {
			margin-top: 15px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;

			input {
				margin-right: 15px;
				cursor: pointer;
			}
		}
	`,
};

export const Text = {
	base: css`
		font-size: 0.9rem;

		a {
			display: inline;
			font-weight: 600;
			text-decoration: underline;
		}
	`,
};

export const ButtonWrapper = {
	base: css`
		.${ButtonRoot.base} {
			width: 100%;
			color: inherit;
			background-color: transparent;
		}
	`,
};

export const Checkmark = {
	base: css`
		@keyframes ams-zoom-in {
			from {
				opacity: 0;
				transform: scale3d(0.3, 0.3, 0.3);
			}

			50% {
				opacity: 1;
			}
		}

		width: 20px;
		height: 20px;
		background: #fff;
		z-index: 2;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 50%;
		pointer-events: none;
		animation: ams-zoom-in 0.1s ease-in-out;

		svg {
			fill: #83cf92;
			position: absolute;
			top: -21px;
			right: -21px;
			width: 60px;
			height: 60px;
		}
	`,
};

export const SentMailAgainButtonWrapper = {
	base: css`
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		> p {
			flex-shrink: 0;
			margin-right: 10px;
		}

		.${ButtonRoot.base} {
			> span {
				padding: 0;
				text-decoration: underline;
			}
			&:after {
				content: none;
			}
		}
	`,
};
