import { FormEvent, useState } from 'react';
import { Root } from './styles';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { BackIcon, Button, H1, P } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { updateProfile } from 'firebase/auth';
import { auth } from '../../../firebase';
import { Avatars, ErrorMessages } from '../../../configs/constants';
import ProfileImageWidget from '../../widgets/profileImage/ProfileImageWidget';
import ProfileNameWidget from '../../widgets/profileName/ProfileNameWidget';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function RegistrationProfilePage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [profileName, setProfileName] = useState<string | null>('');
	const [profileImageId, setProfileImageId] = useState(0);

	const isNextStepAllowed = profileName?.trim() && Avatars?.[profileImageId];

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			const user = auth.currentUser;

			try {
				if (user) {
					await updateProfile(user, {
						displayName: profileName,
						photoURL: Avatars?.[profileImageId],
					});
					await sendEmailVerification(user, appState);
					navigate(`../${PAGE_TYPES.REGISTRATION_MAIL_SENT}`);
				} else {
					throw Error('No User Account found');
				}
			} catch (error) {
				console.error(error);
				setMessage(ErrorMessages.DEFAULT);
				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<div className={cx(BackButtonWrapper.base)}>
					<Button
						text={STRINGS.registrationProfilePage.back.button}
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						href={buildMainLink(PAGE_TYPES.START)}
						as={Link}
					/>
				</div>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<p className={cx(P.base)}>{STRINGS.registrationProfilePage.form.step_3.text}</p>
					<h1 className={cx(H1.base)}>{STRINGS.registrationProfilePage.form.step_3.headline}</h1>
					<p className={cx(P.base)}>{STRINGS.registrationProfilePage.form.step_3.kicker}</p>
					<div className={cx(Mask.base)}>
						<ProfileNameWidget setProfileName={setProfileName} />
						<ProfileImageWidget
							profileImageId={profileImageId}
							setProfileImageId={setProfileImageId}
							isSmallPage
						/>
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text="Weiter"
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!isNextStepAllowed}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default RegistrationProfilePage;
