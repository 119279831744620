import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Root, Page, Listitem, List } from './styles';
import { cx } from '@linaria/core';
import {
	Row,
	Column,
	H2,
	PageTitle,
	MainBox,
	Select,
	P,
	Switch,
	Button,
	BackIcon,
} from '@ard-online/component-styleguide';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { THEME_TYPES } from '../../../configs/types';
import { useContextActions } from '../../../services/actions/ActionsService';
import { AnyFunction } from '../../../types';
import settingsService from '../../../services/settings/SettingsService';
import { useTrackPageImpression } from '../../../services/tracking';

function useOnClickTrackingSwitch(setTrackingEnabled: AnyFunction) {
	return useCallback(() => {
		const newState = !settingsService.trackingEnabled;
		settingsService.trackingEnabled = newState;
		setTrackingEnabled(newState);
	}, [settingsService.trackingEnabled]);
}

function SettingsPage() {
	/** Variables */
	const [trackingEnabled, setTrackingEnabled]: [boolean, AnyFunction] = useState(false);
	const { setTheme } = useContextActions();
	const onClickTrackingSwitchMemo = useOnClickTrackingSwitch(setTrackingEnabled);
	const navigate = useNavigate();

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		setTrackingEnabled(settingsService.trackingEnabled);
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(MainBox.base, Page.base, Root.base)}>
			<main>
				<Row justifyContent="center">
					<Column xs={12} s={8} m={8}>
						<Button
							text="Zurück"
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							as={Link}
							onClick={() => {
								navigate(-1);
							}}
						/>
					</Column>
				</Row>
				<Row justifyContent="center">
					<Column xs={12} s={8} m={8}>
						<h1 className={cx(PageTitle.base)}>Einstellungen</h1>
						<h2 className={cx(H2.base)}>Datenschutz</h2>
						<ul className={cx(List.base)}>
							<li className={cx(Listitem.base)}>
								<div>
									<p className={cx(P.base)}>Anonyme Nutzungsstatistiken (Zählpixel) übertragen</p>
									<span>Anonyme Nutzungsstatistiken helfen uns, die Website zu verbessern.</span>
								</div>
								<Switch
									id="trackingSwitch"
									isEnabled={trackingEnabled}
									onClick={onClickTrackingSwitchMemo}
									label="Anonyme Nutzungsstatistiken (Zählpixel) übertragen"
								/>
							</li>
						</ul>
					</Column>
				</Row>
				<Row justifyContent="center">
					<Column xs={12} s={8} m={8}>
						<h2 className={cx(H2.base)}>Darstellung</h2>
						<ul className={cx(List.base)}>
							<li className={cx(Listitem.base)}>
								<p className={cx(P.base)}>Design wählen</p>
								<li>
									<Select label="Design auswählen" onChange={setTheme}>
										<option value={THEME_TYPES.DEFAULT} aria-label="Automatisches Design">
											Automatisch
										</option>
										<option value={THEME_TYPES.LIGHT} aria-label="Helles Design">
											Hell
										</option>
										<option value={THEME_TYPES.DARK} aria-label="Dunkles Design">
											Dunkel
										</option>
									</Select>
								</li>
							</li>
						</ul>
					</Column>
				</Row>
			</main>
		</div>
	);
}

export default SettingsPage;
