import userService from '../user/UserService';
import { User } from '../../types';

export default async function fetchAddOrEditUser(
	imageId?: number,
	displayName?: string,
	userId?: string,
): Promise<User> {
	try {
		const idToken = userService.idToken;
		let fetchUrl = '/users';
		if (userId) {
			fetchUrl += `/${userId}`;
		}
		const res = await fetch(fetchUrl, {
			method: userId ? 'PATCH' : 'POST',
			headers: {
				'X-Authorization': `Bearer ${idToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				imageId,
				displayName,
			}),
		});

		if (res.ok) {
			return res.status === 204 ? null : await res.json();
		} else {
			throw new Error(`Error ${userId ? 'editing' : 'adding'} user | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		throw error;
	}
}
