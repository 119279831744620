import { useContextActions } from '../../../services/actions/ActionsService';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { LOGIN_TYPES, INFO_TYPES, MESSAGE_TYPES, MODE_TYPES, PAGE_TYPES } from '../../../configs/types';
import RegistrationEmailPage from '../../pages/registration/RegistrationEmailPage';
import RegistrationPasswordPage from '../../pages/registration/RegistrationPasswordPage';
import RegistrationProfilePage from '../../pages/registration/RegistrationProfilePage';
import RegistrationMailSentPage from '../../pages/registration/RegistrationMailSentPage';
import EmailActionsPage from '../../pages/emailActions/EmailActionsPage';
import ForgotPasswordPage from '../../pages/forgotPassword/ForgotPasswordPage';
import NewPasswordPage from '../../pages/newPassword/NewPasswordPage';
import LoginPage from '../../pages/login/LoginPage';
import EditAccountPage from '../../pages/editAccount/EditAccountPage';
import DeleteAccountPage from '../../pages/deleteAccount/DeleteAccountPage';
import AgeVerificationIdCardPage from '../../pages/ageVerification/AgeVerificationIdCardPage';
import AgeVerificationPinPage from '../../pages/ageVerification/AgeVerificationPinPage';
import AgeVerificationDeletePage from '../../pages/ageVerification/AgeVerificationDeletePage';
import InfoPage from '../../pages/info/InfoPage';
import NotFoundPage from '../../pages/notFound/NotFoundPage';
import ImprintPage from '../../pages/imprint/ImprintPage';
import Redirect from './Redirect';
import { buildInfoLink } from '../../../services/link';
import AddProfilePage from '../../pages/profile/AddProfilePage';
import EditProfilePage from '../../pages/profile/EditProfilePage';
import DeleteProfilePage from '../../pages/profile/DeleteProfilePage';
import UserCodeLoginPage from '../../pages/login/UserCodeLoginPage';
import OidcLoginPage from '../../pages/login/OidcLoginPage';
import LoginConfirmPage from '../../pages/login/LoginConfirmPage';
import userService from '../../../services/user/UserService';
import LoadingIndicatorWidget from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { redirectToRefererApp } from '../../../services/misc';
import PrivacyPage from '../../pages/privacy/PrivacyPage';
import TermsOfUse from '../../pages/termsOfUse/TermsOfUsePage';
import SettingsPage from '../../pages/settings/SettingsPage';

function AppRoutes() {
	/** Variables */
	const { appState } = useContextActions();
	const { pathname } = useLocation();

	/** JSX */
	return (
		<Routes>
			<Route
				path={`/${PAGE_TYPES.REGISTRATION}`}
				element={userService.userId ? <Redirect to={`/${PAGE_TYPES.EDIT_ACCOUNT}`} /> : <Outlet />}
			>
				<Route index element={<RegistrationEmailPage />} />
				<Route path={PAGE_TYPES.REGISTRATION_PASSWORD} element={<RegistrationPasswordPage />} />
				<Route path={PAGE_TYPES.REGISTRATION_PROFILE} element={<RegistrationProfilePage />} />
				<Route path={PAGE_TYPES.REGISTRATION_MAIL_SENT} element={<RegistrationMailSentPage />} />
			</Route>
			<Route
				path={`/${PAGE_TYPES.EMAIL_ACTIONS}`}
				element={userService.userId ? <Redirect to={`/${PAGE_TYPES.EDIT_ACCOUNT}`} /> : <EmailActionsPage />}
			/>
			<Route
				path={`/${PAGE_TYPES.FORGOT_PASSWORD}`}
				element={userService.userId ? <Redirect to={`/${PAGE_TYPES.EDIT_ACCOUNT}`} /> : <ForgotPasswordPage />}
			/>
			<Route path={`/${PAGE_TYPES.NEW_PASSWORD}`} element={<NewPasswordPage />} />
			<Route
				path={`/${PAGE_TYPES.LOGIN_CONFIRM}`}
				element={userService.userId ? <LoginConfirmPage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			/>
			<Route
				path={`/${PAGE_TYPES.START}`}
				element={(() => {
					if (appState.config.modeType === MODE_TYPES.OIDC) {
						return <OidcLoginPage />;
					} else if (userService.userId) {
						if (appState.navigation.redirectUrl && pathname === `/${PAGE_TYPES.START}`) {
							redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.LOGGED_IN);
							return <LoadingIndicatorWidget />;
						} else {
							return <Redirect to={`/${PAGE_TYPES.EDIT_ACCOUNT}`} />;
						}
					} else {
						return <LoginPage />;
					}
				})()}
			/>
			<Route path={`/${PAGE_TYPES.USER_CODE}`}>
				<Route index element={<UserCodeLoginPage />} />
				<Route path=":userCode" element={<UserCodeLoginPage />} />
			</Route>
			<Route
				path={`/${PAGE_TYPES.EDIT_ACCOUNT}`}
				element={
					userService.userId ? (
						userService.isSubUser ? (
							<Redirect to={`/${PAGE_TYPES.EDIT_PROFILE}`} />
						) : userService.loginType === LOGIN_TYPES.OIDC_ZDF ? (
							<Redirect to={buildInfoLink(INFO_TYPES.ZDF_USER)} />
						) : (
							<EditAccountPage />
						)
					) : (
						<Redirect to={`/${PAGE_TYPES.START}`} />
					)
				}
			/>
			<Route
				path={`/${PAGE_TYPES.ADD_PROFILE}`}
				element={userService.userId ? <AddProfilePage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			/>
			<Route
				path={`/${PAGE_TYPES.EDIT_PROFILE}/:userId?`}
				element={userService.userId ? <EditProfilePage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			/>
			<Route
				path={`/${PAGE_TYPES.DELETE_PROFILE}/:userId`}
				element={userService.userId ? <DeleteProfilePage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			/>
			<Route
				path={`/${PAGE_TYPES.DELETE_ACCOUNT}`}
				element={
					userService.userId ? (
						userService.loginType === LOGIN_TYPES.OIDC_ZDF ? (
							<Redirect to={buildInfoLink(INFO_TYPES.ZDF_USER)} />
						) : (
							<DeleteAccountPage />
						)
					) : (
						<Redirect to={`/${PAGE_TYPES.START}`} />
					)
				}
			/>
			<Route
				path={`/${PAGE_TYPES.AGE_VERIFICATION}`}
				element={
					userService.userId ? (
						userService.loginType === LOGIN_TYPES.OIDC_ZDF ? (
							<Redirect to={buildInfoLink(INFO_TYPES.ZDF_USER)} />
						) : (
							<Outlet />
						)
					) : (
						<Redirect to={`/${PAGE_TYPES.START}`} />
					)
				}
			>
				<Route index element={<AgeVerificationIdCardPage />} />
				<Route path={PAGE_TYPES.AGE_VERIFICATION_PIN} element={<AgeVerificationPinPage />} />
				<Route path={PAGE_TYPES.AGE_VERIFICATION_PIN_CHANGE} element={<AgeVerificationPinPage />} />
				<Route path={PAGE_TYPES.AGE_VERIFICATION_DELETE} element={<AgeVerificationDeletePage />} />
			</Route>
			<Route path={`/${PAGE_TYPES.INFO}/:infoType`} element={<InfoPage />} />
			{/* redirect deprecated routes: */}
			<Route path={'/profil-verwalten'} element={<Redirect to={`/${PAGE_TYPES.EDIT_ACCOUNT}`} />} />
			{/* remove if MT 2.44. is live */}
			<Route
				path={`/${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.AGE_VERIFICATION_ID_CARD}`}
				element={<Redirect to={`/${PAGE_TYPES.AGE_VERIFICATION}`} />}
			/>
			{/* fallback:*/}
			<Route path="*" element={<NotFoundPage />} />
			<Route path={`/${PAGE_TYPES.IMPRINT}`} element={<ImprintPage />} />
			<Route path={`/${PAGE_TYPES.PRIVACY}`} element={<PrivacyPage />} />
			<Route path={`/${PAGE_TYPES.TERMS_OF_USE}`} element={<TermsOfUse />} />
			<Route path={`/${PAGE_TYPES.SETTINGS}`} element={<SettingsPage />} />
		</Routes>
	);
}

export default AppRoutes;
