import { css } from '@linaria/core';
import { Mask } from '../../app/styles';
import { P } from '@ard-online/component-styleguide';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

// TODO Linaria mk 2023-07-11: this seems to be unused
// export const Description = styled(P)``;

export const Root = {
	base: css`
		max-height: 100%;

		h3 {
			text-align: left;
		}

		.${Mask.base} {
			.${P.base} {
				margin-top: -5px;
				margin-bottom: 0px;
			}
		}
	`,
};

export const ButtonWrapper = {
	base: css`
		.${ButtonRoot.base} {
			width: 100%;
			color: inherit;
			background-color: transparent;
		}
	`,
};
