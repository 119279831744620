const HOST = 'https://accounts.ard.de';

const prodEnv = {
	STAGE_TYPE: 'prod',
	HOST: HOST,
	SSO_CLIENT_HOSTS: [
		HOST,
		'https://sso.ardmediathek.de',
		'https://sso.ardaudiothek.de',
		'https://sso.tagesschau.de',
		'https://sso.sportschau.de',
	], // first must be AMS
	FIREBASE: {
		apiKey: 'AIzaSyCEvA_fVGNMRcS9F-Ubaaa0y0qBDUMlh90',
		authDomain: 'ardmt-prod.web.app',
		projectId: 'ardmt-prod',
		//messagingSenderId: '20599643556',
		appId: '1:20599643556:web:d8738c34cb23f17770f531',
	},
	FIREBASE_TOKEN_KEY: 'AIzaSyCF2YOOMBFsvcJ764b3n2wDAf8cVZQlmgE',
	TRACKING_PROXY_HOST: 'https://accounts.ard.de/t',
	PIANO_ENVIRONMENT_ID: '634416',
};

export default prodEnv;
