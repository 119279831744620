import { KeyValueStorageService } from '../storage/StorageService';
import { IDB_STORE_TYPES, STORAGE_KEYS } from '../../configs/types';

const CAMPAIGN_VALIDITY_DURATION = 1000 * 60 * 60 * 24 * 30; // 30 days

enum PIANO_CAMPAIGN_SEARCHPARAM_KEY {
	MEDIUM = 'at_medium',
	CAMPAIGN = 'at_campaign',
	TARGET = 'at_target',
	ADG = 'at_adg',
	AD = 'at_ad',
	TERM = 'at_term',
}

type Campaign = {
	medium: string;
	campaign: string;
	target?: string;
	adg?: string;
	ad?: string;
	term?: string;
	timestamp: number;
};

function getCampaignFromUrl() {
	const searchParams = new URLSearchParams(window.location.search);
	const medium = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.MEDIUM);
	const campaign = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.CAMPAIGN);
	const ad = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.AD) ?? undefined;
	const adg = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.ADG) ?? undefined;
	const target = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.TARGET) ?? undefined;
	const term = searchParams.get(PIANO_CAMPAIGN_SEARCHPARAM_KEY.TERM) ?? undefined;

	if (campaign && medium) {
		return {
			medium,
			campaign,
			ad,
			adg,
			target,
			term,
			timestamp: Date.now(),
		};
	}
}
class SettingsService extends KeyValueStorageService<string | boolean | Map<string, number> | Campaign | undefined> {
	constructor(storageName: string) {
		super(storageName);

		super.addItemToMemory(STORAGE_KEYS.TRACKING_ENABLED, true);
	}

	async init() {
		if (this.trackingEnabled) {
			const campaign = getCampaignFromUrl();
			if (campaign) {
				this.campaign = campaign;
			}
		}

		await super.init();
	}

	get campaign(): Campaign | undefined {
		const campaign = (this.getItem(STORAGE_KEYS.CAMPAIGN) as Campaign) ?? undefined;

		if (campaign && Date.now() - campaign.timestamp > CAMPAIGN_VALIDITY_DURATION) {
			this.campaign = undefined;
			return this.campaign;
		}

		return campaign;
	}

	private set campaign(campaign: Campaign | undefined) {
		this.addItem(STORAGE_KEYS.CAMPAIGN, campaign);
	}

	get trackingEnabled(): boolean {
		return (this.getItem(STORAGE_KEYS.TRACKING_ENABLED) as boolean) ?? false;
	}

	set trackingEnabled(value: boolean) {
		this.addItem(STORAGE_KEYS.TRACKING_ENABLED, value);
	}
}

const settingsService = new SettingsService(IDB_STORE_TYPES.SETTINGS);
export default settingsService;
