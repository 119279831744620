import { Root } from './styles';
import { P, ClearIcon, CheckmarkIcon } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

type Props = {
	children: string;
	type?: 'error' | 'success';
};

function MessageWidget({ children, type = 'error' }: Props) {
	/** JSX */
	return (
		<div className={cx(Root.base, Root.type[type])} role="alert">
			{type === 'error' ? <ClearIcon /> : <CheckmarkIcon />}
			<p className={cx(P.base)}>{children}</p>
		</div>
	);
}

export default MessageWidget;
