import { css } from '@linaria/core';

export const DeleteButton = {
	base: css`
		margin: 0 auto;
	`,
};

export const Root = {
	base: css`
		max-height: 100%;

		h3 {
			text-align: left;
		}

		ul {
			list-style: none;
			padding-left: 30px;
			li {
				margin-bottom: 10px;
			}
		}
	`,
};

export const BulletpointIcon = {
	base: css`
		width: 16px;
		height: 16px;
		background: #fff;
		z-index: 1;
		position: absolute;
		flex-shrink: 0;
		border-radius: 50%;
		pointer-events: none;
		margin-top: 4px;
		left: 0;

		svg {
			fill: rgb(var(--global-error)) !important;
			position: absolute;
			top: -12px;
			right: -12px;
			width: 40px !important;
			height: 40px !important;
		}
	`,
};
