import matches from 'validator/lib/matches';
import isEmail from 'validator/lib/isEmail';
//TODO: soll in einen ValidationService verschoben werden
export function isValidPassword(password: string): boolean {
	return matches(password, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/);
}
export function hasNumber(password: string): boolean {
	return matches(password, /.*[0-9].*/);
}
export function hasAtLeast8Characters(password: string): boolean {
	return matches(password, /^(?=.*[a-zA-Z\d]).{8,100}$/);
}
export function hasUpperAndLowercase(password: string): boolean {
	return matches(password, /^(?=.*[a-z])(?=.*[A-Z])/);
}
export function isValidEmail(email: string): boolean {
	return isEmail(email, { allow_utf8_local_part: false });
}
export function isZdfOidcClient(clientId: string): boolean {
	return (
		clientId === 'oZKK3boGfqqahUBoDozvAeGwh48LACct' ||
		clientId === 'h1b30jGErp71vI2yQfov71z9z1V4Vjtl' ||
		clientId === 'Qb3LeI7LLpRfVzTSrojA4hEG1esm1UVN' ||
		clientId === 'pNy50Omzxgir0jY6WwP4nD2dTxo9hTeh'
	);
}
