import { FormEvent, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CodeInputWrapper, Headline, LoginArea, Root } from './styles';
import { Button, Input, P, Row } from '@ard-online/component-styleguide';
import { Box, Form, Mask } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import LoadingIndicator from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { isTouchDevice } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { CLIENT_TYPES, INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import PromoBoardWidget from '../../widgets/promoBoard/PromoBoardWidget';
import { AnyObject } from '../../../types';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import CreateAccountButtonWidget from '../../widgets/createAccountButton/CreateAccountButtonWidget';
import { ErrorMessages } from '../../../configs/constants';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function getDeviceAuthorizationApiUrl(userCode: string) {
	return `/device/authorize/${userCode}`;
}

function UserCodeLoginPage() {
	/** Variables */
	const navigate = useNavigate();
	const { userCode: userCodeParam } = useParams<{ userCode: string }>();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const [userCode, setUserCode] = useState(userCodeParam);
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const hasPromoBoard =
		appState.navigation.clientId === CLIENT_TYPES.MEDIATHEK ||
		appState.navigation.clientId === CLIENT_TYPES.AUDIOTHEK;

	let submittable = false;
	if ((email && password && userCode) || (userService.userId && userCode)) {
		submittable = true;
	}

	let showLoadingIndicator = false;
	if (userService.userId && loading) {
		showLoadingIndicator = true;
	}

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (submittable && !loading) {
			if (userService.userId) {
				await authorizeDevice();
			} else {
				setLoading(true);
				setMessage(null);
				try {
					await loginWithEmailPassword(email as string, password);
					await sso(getDeviceAuthorizationApiUrl(userCode as string));
				} catch (error) {
					error instanceof Error && setMessage(error.message);
					setLoading(false);
				}
			}
		}
	}

	// ToDo jonatan 26.10.22 extract this duplicated code, pass handleLoginSuccess as callback?
	async function authorizeDevice() {
		if (userCode) {
			const res = await fetch(getDeviceAuthorizationApiUrl(userCode), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					idToken: userService.idToken,
					refreshToken: userService.refreshToken,
				}),
				redirect: 'manual',
			});

			if (res.type === 'opaqueredirect') {
				navigate(buildInfoLink(INFO_TYPES.DEVICE_AUTHORIZATION_COMPLETED));
			} else if (!res.ok) {
				if (userCodeParam) {
					navigate(buildInfoLink(INFO_TYPES.ERROR_DEVICE_AUTHORIZATION));
				} else {
					setLoading(false);
					setMessage(ErrorMessages.INVALID_USER_CODE);
				}
			}
		}
	}

	function onUserCodeInputChange(e: AnyObject) {
		if (e.isValid) {
			setUserCode(e.value);
		} else {
			setUserCode('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			{showLoadingIndicator ? (
				<LoadingIndicator />
			) : (
				<>
					<main className={cx(Box.base, hasPromoBoard && Box.hasPromoboard)}>
						<h1 className={cx(Headline.base)}>
							{userService.userId
								? STRINGS.userCodeLoginPage.loggedIn.headline
								: STRINGS.userCodeLoginPage.loggedOut.headline}
						</h1>
						{hasPromoBoard && <PromoBoardWidget />}
						<div className={cx(LoginArea.base, hasPromoBoard && LoginArea.hasPromoboard)}>
							<form className={cx(Form.base)} onSubmit={onSubmit}>
								<div className={cx(Mask.base)}>
									{!userService.userId && (
										<CredentialsWidget
											email={email}
											setEmail={setEmail}
											setPassword={setPassword}
										/>
									)}

									{!userCodeParam ? (
										<>
											{userService.userId && (
												<p className={cx(P.base)}>
													{STRINGS.userCodeLoginPage.loggedIn.prompt.text}
												</p>
											)}
											<div className={cx(CodeInputWrapper.base)}>
												<Input
													type="text"
													label="Code"
													isRequired
													errorMessage="Kein valider Code"
													htmlAttributes={{
														minLength: 5,
														title: 'Code eingeben',
													}}
													hasFocus={!!userService.userId && !isTouchDevice()}
													onChange={onUserCodeInputChange}
													name="code"
												/>
											</div>
										</>
									) : (
										<>
											{userService.userId && (
												<p className={cx(P.base)}>
													{STRINGS.userCodeLoginPage.loggedOut.prompt.text}
												</p>
											)}
										</>
									)}
								</div>
								{message && <MessageWidget>{message}</MessageWidget>}
								<Row justifyContent="space-evenly">
									<Button
										text="Anmelden"
										type={BUTTON_TYPES.PRIMARY}
										onClick={onSubmit}
										isDisabled={!submittable}
										isLoading={loading}
										htmlAttributes={{
											type: 'submit',
										}}
									/>
									{userCodeParam && (
										<Button
											text="Abbrechen"
											type={BUTTON_TYPES.SECONDARY}
											href={buildMainLink(PAGE_TYPES.START)}
											as={Link}
										/>
									)}
								</Row>
							</form>
							<CreateAccountButtonWidget />
						</div>
					</main>
					{ssoForm}
				</>
			)}
		</div>
	);
}

export default UserCodeLoginPage;
