import { PAGE_TYPES } from '../../configs/types';
export default function buildInfoLink(path?: string, redirectUrl?: string) {
	let url = `${PAGE_TYPES.INFO}`;

	if (path) {
		url += `/${path}`;
	}

	if (redirectUrl) {
		url += `?redirect_uri=${redirectUrl}`;
	}

	return `/${url}`;
}
