import { Navigate } from 'react-router-dom';

type Props = {
	to: string;
	historyPush?: boolean;
};

function Redirect({ to, historyPush = false }: Props) {
	return <Navigate to={to} replace={!historyPush} />;
}

export default Redirect;
