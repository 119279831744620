import { AnyFunction, AnyObject } from '../../../types';
import { Button, Input, P } from '@ard-online/component-styleguide';
import { Link } from 'react-router-dom';
import { isValidEmail } from '../../../services/validation';
import { isTouchDevice } from '../../../services/misc';
import { PwForgottenLink } from './styles';
import { PAGE_TYPES } from '../../../configs/types';
import { RowAction } from '../../app/styles';
import userService from '../../../services/user/UserService';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function CredentialsWidget({
	email,
	setEmail,
	setPassword,
}: {
	email: string | null;
	setEmail: AnyFunction;
	setPassword: AnyFunction;
}) {
	function onEmailInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	function onPasswordInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword('');
		}
	}

	return (
		<>
			<p className={cx(P.base)}>Anmelden</p>
			{userService.email ? (
				<div className={cx(RowAction.base)}>
					<span>{email}</span>
					<input type="hidden" name="userId" value={email ?? ''} />
					<Button
						type={BUTTON_TYPES.TEXT}
						text={STRINGS.credentialsWidget.emailExists.button}
						htmlAttributes={{ title: 'Andere E-Mail-Adresse eingeben', type: 'button' }}
						onClick={() => {
							userService.email = null;
							setEmail(null);
						}}
					/>
				</div>
			) : (
				<Input
					type="email"
					label="E-Mail-Adresse"
					isRequired
					errorMessage="Keine valide E-Mail-Adresse"
					onChange={onEmailInputChange}
					validation={isValidEmail}
					hasFocus={!isTouchDevice()}
					htmlAttributes={{
						autoComplete: 'email',
						title: STRINGS.credentialsWidget.emailInput.text,
					}}
					name="userId"
					id="userIdInput"
				/>
			)}
			{!(userService.userId && userService.email === email) && (
				<Input
					type="password"
					label="Passwort"
					isRequired
					errorMessage="Kein valides Passwort"
					onChange={onPasswordInputChange}
					hasFocus={!!email && !isTouchDevice()}
					htmlAttributes={{
						autoComplete: 'current-password',
						title: STRINGS.credentialsWidget.passwordInput.text,
					}}
					name="password"
					id="passwordInput"
				/>
			)}
			<Link
				className={cx(PwForgottenLink.base)}
				to={`/${PAGE_TYPES.FORGOT_PASSWORD}${email ? `#email=${email}` : ''}`}
			>
				{STRINGS.credentialsWidget.button}
			</Link>
		</>
	);
}

export default CredentialsWidget;
