import { AppState } from '../../configs/defaultState';
import { sendEmailVerification as sendEmailVerificationViaFirebase, User } from 'firebase/auth';
import getContinueUrl from '../url/getContinueUrl';

export default async function sendEmailVerification(user: User, appState: AppState): Promise<void> {
	const actionCodeSettings = {
		url: getContinueUrl(appState, user.email),
	};
	return await sendEmailVerificationViaFirebase(user, actionCodeSettings);
}
