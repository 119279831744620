const HOST = 'https://accounts-dev.ard.de';

const devEnv = {
	STAGE_TYPE: 'dev',
	HOST: HOST,
	SSO_CLIENT_HOSTS: [HOST, 'https://sso-dev.ardmediathek.de', 'https://sso-dev.ardaudiothek.de'], // first must be AMS
	FIREBASE: {
		apiKey: 'AIzaSyDMNK9ktrIzPznFjKkbGHyTexGfPn22bF4',
		authDomain: 'ardmt-dev.web.app',
		projectId: 'ardmt-dev',
		//messagingSenderId: '422163375345',
		appId: '1:422163375345:web:ea368176fb1b83b457b550',
	},
	FIREBASE_TOKEN_KEY: 'AIzaSyCNfSQSxfZLc-CK7aY75WoE3WLOoFKgw-U',
	TRACKING_PROXY_HOST: 'https://accounts-dev.ard.de/t',
	PIANO_ENVIRONMENT_ID: '634415',
};

export default devEnv;
