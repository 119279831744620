import {
	AccountSection,
	AddProfileWrapper,
	BreakParagraph,
	Checkmark,
	CustomBox,
	CustomColumn,
	PasswordWrapper,
	PinRow,
	ProfilesSection,
	Root,
} from './styles';
import { Button, Checkmark2Icon, Column, H1, H2, P, PlusIcon, Row } from '@ard-online/component-styleguide';
import { Link } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildAgeVerificationLink, buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Box } from '../../app/styles';
import { cx } from '@linaria/core';
import ProfileListWidget from '../../widgets/ProfileListWidget/ProfileListWidget';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { MAX_SUB_USERS } from '../../../configs/constants';
import InfoBoxWidget from '../../widgets/infoBox/InfoBoxWidget';

const HELP_LINK_URL = 'https://hilfe.ard.de/artikel/alterssperre-und-altersbestaetigung/';

function EditAccountPage() {
	/** Variables */

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<>
			<div className={cx(Root.base)}>
				<main className={cx(Box.base, Box.type.large, CustomBox.base)}>
					{userService.userId && (
						<>
							<section className={cx(ProfilesSection.base)}>
								<header>
									<h1 className={cx(H1.base)}>{STRINGS.editAccountPage.headline}</h1>
								</header>
								<InfoBoxWidget text={STRINGS.editAccountPage.infoBox.text} />
								<Row>
									<ProfileListWidget />
									<Column all={12} p={0} className={cx(AddProfileWrapper.base)}>
										<Button
											text="Profil hinzufügen"
											type={BUTTON_TYPES.SECONDARY}
											iconLeft={<PlusIcon />}
											href={buildMainLink(PAGE_TYPES.ADD_PROFILE)}
											as={Link}
											isDisabled={userService.users.size > MAX_SUB_USERS}
										/>
									</Column>
								</Row>
							</section>
							<section className={cx(AccountSection.base)}>
								<header>
									<h1 className={cx(H1.base)}>Konto-Einstellungen</h1>
								</header>

								<Row>
									<Column className={cx(CustomColumn.base)} all={12} p={0}>
										<h2 className={cx(H2.base)}>Meine Daten</h2>
									</Column>
									<Column className={cx(CustomColumn.base)} all={12} p={0}>
										<Row my={0}>
											<Column className={cx(CustomColumn.base)} all={12} p={0}>
												<p className={cx(P.base, BreakParagraph.base)}>
													E-Mail: {userService.email}
												</p>
											</Column>
											<Column className={cx(CustomColumn.base)} all={12} s={7} p={0}>
												<p className={cx(P.base, PasswordWrapper.base)}>
													Passwort: *************
												</p>
											</Column>
											<Column
												className={cx(CustomColumn.base, CustomColumn.type.link)}
												all={12}
												s={5}
												p={0}
											>
												<Link
													to={buildMainLink(PAGE_TYPES.NEW_PASSWORD)}
													title="Passwort ändern"
												>
													Passwort ändern
												</Link>
											</Column>
										</Row>
									</Column>
								</Row>
								<Row>
									<Column className={cx(CustomColumn.base)} all={12} p={0}>
										<h2 className={cx(H2.base)}>Altersfreigabe</h2>
									</Column>
									<Column className={cx(CustomColumn.base)} all={12} p={0}>
										{userService.isAgeVerified ? (
											<>
												<Row my={0}>
													<Column
														className={cx(CustomColumn.base, CustomColumn.type.check)}
														all={12}
														s={7}
														p={0}
													>
														<div className={cx(Checkmark.base)}>
															<Checkmark2Icon />
														</div>
														<span>Altersfreigabe erfolgreich</span>
													</Column>
													<Column
														className={cx(CustomColumn.base, CustomColumn.type.link)}
														all={12}
														s={5}
														p={0}
													>
														<Link
															to={buildAgeVerificationLink(
																PAGE_TYPES.AGE_VERIFICATION_DELETE,
															)}
															title="Altersfreigabe aufheben"
														>
															Altersfreigabe aufheben
														</Link>
													</Column>
												</Row>
												<Row className={cx(PinRow.base)}>
													<Column
														className={cx(CustomColumn.base, CustomColumn.type.check)}
														all={12}
														s={8}
														p={0}
													>
														<div className={cx(Checkmark.base)}>
															<Checkmark2Icon />
														</div>
														<span>Jugendschutz-Code: ****</span>
													</Column>
													<Column
														className={cx(CustomColumn.base, CustomColumn.type.link)}
														all={12}
														s={4}
														p={0}
													>
														<Link
															to={buildAgeVerificationLink(
																PAGE_TYPES.AGE_VERIFICATION_PIN_CHANGE,
															)}
															title="Code ändern"
														>
															Code ändern
														</Link>
													</Column>
												</Row>
											</>
										) : (
											<Row my={0}>
												<Column className={cx(CustomColumn.base)} all={12} s={7} p={0}>
													<p className={cx(P.base)}>
														{STRINGS.editAccountPage.ageNotVerified.text}
														<a href={HELP_LINK_URL} target="_blank" rel="noreferrer">
															{STRINGS.editAccountPage.ageNotVerified.link.text}
														</a>
													</p>
												</Column>
												<Column
													className={cx(CustomColumn.base, CustomColumn.type.link)}
													all={12}
													s={5}
													p={0}
												>
													<Link
														to={buildMainLink(PAGE_TYPES.AGE_VERIFICATION)}
														title="Alter bestätigen"
													>
														{STRINGS.editAccountPage.ageNotVerified.confirmAge.text}
													</Link>
												</Column>
											</Row>
										)}
									</Column>
								</Row>

								<Row>
									<Column className={cx(CustomColumn.base)} all={12} p={0}>
										<h2 className={cx(H2.base)}>Konto</h2>
									</Column>
									<Column className={cx(CustomColumn.base, CustomColumn.type.link)} all={12} p={0}>
										<Link
											title="Konto löschen"
											to={buildMainLink(PAGE_TYPES.DELETE_ACCOUNT)}
											id="deleteAccount"
										>
											{STRINGS.editAccountPage.deleteAccount.button}
										</Link>
									</Column>
								</Row>
							</section>
						</>
					)}
				</main>
			</div>
		</>
	);
}

export default EditAccountPage;
