import { Root } from './styles';
import { BackButtonWrapper, Box } from '../../app/styles';
import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';
import { BackIcon, Button, PageTitle } from '@ard-online/component-styleguide';
import useParseSophoraCopyText from '../../../services/misc/useParseSpohoraCopyText';
import { EXTERNAL_URLS } from '../../../configs/constants';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useFetch } from '../../../services/fetch';
import { AnyObject } from '../../../types';

function TermsOfUse() {
	/** Variables */
	const data: AnyObject = useFetch(
		`${EXTERNAL_URLS.SOPHORA_HOST}/Nutzungsbedingungen-ARD-Konto-100~_plaintext-true.json`,
	);
	const navigate = useNavigate();

	const title = data?.headline ?? '';
	const termsOfUseText = useParseSophoraCopyText(data);

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<div className={cx(BackButtonWrapper.base)}>
					<Button
						text="Zurück"
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						as={Link}
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
				<h1 className={cx(PageTitle.base)}>{title}</h1>
				{termsOfUseText}
			</main>
		</div>
	);
}

export default TermsOfUse;
