import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';
import { IconWrapper, Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';
import { Root as SelectRoot } from '@ard-online/component-styleguide/dist/components/Select/styles';
import { ButtonWrapper as ShowMoreButton } from '@ard-online/component-styleguide/dist/components/ShowMore/styles';

export const Root = {
	base: css`
		position: relative;
		background-color: #1c2841;
		z-index: 2;

		.${SelectRoot.base} {
			max-width: 360px;
			margin: 0 auto;
		}
	`,
};

export const FooterRow = {
	base: css`
		margin: 0;
	`,
};

export const Big5IconsWrapper = {
	base: css`
		display: flex;
		width: 100%;
		justify-content: center;

		a {
			width: 64px;
			height: 64px;
			background-color: transparent !important;
			&:hover {
				background-color: transparent !important;
			}

			.${IconWrapper.base}, .${ImageRoot.base} {
				width: 100%;
				height: 100%;
			}
		}
	`,
};

export const Big5List = {
	base: css`
		list-style: none;
		display: flex;
		padding: 0;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;
	`,
};

export const Big5Icon = {
	base: css`
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 10px 2%;

		.${ButtonRoot.base} {
			> div {
				display: block;
			}
		}

		${Media('max', 'xs')} {
			flex-basis: 25%;
		}

		h3 {
			text-transform: none !important;
			margin-top: 10px;
			color: rgb(var(--brand-dark-text-primary));
			text-align: center;
			font-size: 0.75rem;
		}
	`,
};

export const ListColumn = {
	base: css`
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.${ShowMoreButton.base} {
			width: 100%;
			display: flex;
			justify-content: center;
			button {
				background: #fff !important;
				fill: #000e29 !important;
				color: #000e29 !important;
				&:hover {
					color: #fff !important;
					fill: #fff !important;
					background: #2d91ff !important;
				}
			}

			@media only screen and (max-width: 360px) {
				span {
					font-size: 0.9rem;
				}
			}
		}
	`,
};

export const LinkList = {
	base: css`
		width: 100%;
		list-style: none;
		display: flex;
		justify-content: center;
		padding: 0;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba(255, 255, 255, 0.25);
		padding-bottom: 20px;
		margin-bottom: 0;
		li {
			margin: 10px 0;
			width: 100%;
			text-align: center;
		}
		a {
			color: #fff;
		}
	`,
};

export const BottomNav = {
	base: css`
		margin: 20px 0;

		ul {
			list-style: none;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			padding: 0;

			li {
				margin: 10px 0.75rem;
				color: #fff;

				${Media('max', 'xs')} {
					flex-basis: 100%;
					text-align: center;
					margin-right: 0;
				}
				a {
					color: #fff;
				}
			}
		}
	`,
};

export const ThemeSelectWrapper = {
	base: css`
		display: flex;
		align-items: center;
		.${SelectRoot.base} {
			margin-left: 0.5rem;
			select {
				background-color: #1c2841;
				color: #fff;
				border-color: rgba(255, 255, 255, 0.2);
			}

			svg {
				fill: #fff;
			}
		}
	`,
};
