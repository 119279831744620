import { Checkmark } from '../../pages/registration/styles';
import { ProfileImage, ProfileImageContainer } from './styles';
import { Avatars } from '../../../configs/constants';
import { Button, Checkmark2Icon, Image } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

type Props = {
	profileImageId: number;
	setProfileImageId: (value: ((prevState: number) => number) | number) => void;
	isSmallPage?: boolean;
};

function ProfileImageWidget({ profileImageId, setProfileImageId, isSmallPage = false }: Props) {
	return (
		<div className={cx(ProfileImageContainer.base, isSmallPage && ProfileImageContainer.small)}>
			{Avatars.map((imageUrl, index) => {
				const isCurrentProfileImage = profileImageId === index;

				return (
					<div className={cx(ProfileImage.base)} key={index}>
						{isCurrentProfileImage && (
							<div className={cx(Checkmark.base)}>
								<Checkmark2Icon />
							</div>
						)}
						<Button
							iconLeft={
								<Image
									src={imageUrl}
									ratio="1x1"
									alt={isCurrentProfileImage ? 'Ausgewähltes Profilbild' : 'Wählbares Profilbild'}
								/>
							}
							isIconOnly
							text={`Image ${index}`}
							htmlAttributes={{
								type: 'button',
							}}
							isActive={isCurrentProfileImage}
							onClick={() => setProfileImageId(index)}
						/>
					</div>
				);
			})}
		</div>
	);
}

export default ProfileImageWidget;
