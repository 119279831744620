import { useEffect, useRef, useState } from 'react';
import { Input } from '@ard-online/component-styleguide';
import { isTouchDevice } from '../../../services/misc';

const maxTextLength = 20;

const ErrorMessages = {
	DEFAULT: 'Bitte geben Sie einen Namen ein.',
	MAX_LENGTH: `Der Name ist zu lang (max ${maxTextLength} Zeichen).`,
};

type ProfileNameObject = {
	value: string;
	isValid: boolean;
};

function ProfileNameWidget({
	setProfileName,
	initialValue,
}: {
	setProfileName: (name: string | null) => void;
	initialValue?: string | null;
}) {
	const errorMessageRef = useRef<string>('');
	const [name, setName] = useState<string | null | undefined>(initialValue);

	function onInputChange(e: ProfileNameObject) {
		if (e.isValid) {
			setProfileName(e.value);
		} else {
			errorMessageRef.current =
				e.value.trim().length > maxTextLength ? ErrorMessages.MAX_LENGTH : ErrorMessages.DEFAULT;
			setProfileName(null);
		}
		setName(e.value);
	}

	useEffect(() => {
		initialValue && setName(initialValue);
	}, [initialValue]);

	return (
		<Input
			type="text"
			label="Profilname"
			isRequired
			errorMessage={errorMessageRef.current}
			onChange={onInputChange}
			hasFocus={!isTouchDevice()}
			validation={(text) => !!text.trim() && text.trim().length <= maxTextLength}
			htmlAttributes={{ title: 'Profilnamen eingeben' }}
			initialValue={name || ''}
		/>
	);
}

export default ProfileNameWidget;
