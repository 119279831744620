import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		max-height: 100%;

		${Media('max', 'xs')} {
			form > button {
				width: 100%;
			}
		}
	`,
};

export const CustomBox = {
	base: css`
		width: 550px;
	`,
};

export const ButtonsWrapper = {
	base: css`
		display: flex;
		justify-content: space-between;

		${Media('max', 'xs')} {
			flex-direction: column;
		}

		${Media('max', 'xs')} {
			button {
				width: 100%;
			}

			button ~ button {
				margin-top: 16px;
			}
		}
	`,
};
