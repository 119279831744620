import cookie from 'cookie';
import type { User as FirebaseUser } from 'firebase/auth';
import {
	OAuthProvider,
	getAdditionalUserInfo,
	onIdTokenChanged,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
	updateEmail,
	updatePassword,
	updateProfile,
} from 'firebase/auth';
import { Avatars, DEFAULT_USER, StorageKeys } from '../../configs/constants';
import defaultState from '../../configs/defaultState';
import { IDB_STORE_TYPES, LOGIN_TYPES, INFO_TYPES } from '../../configs/types';
import { auth } from '../../firebase';
import type { AnyObject, User } from '../../types';
import {
	fetchAddOrEditUser,
	fetchCustomTokenByCookies,
	fetchCustomTokenByIdToken,
	fetchDeleteUsers,
	fetchUsers,
} from '../fetch';
import { KeyValueStorageService } from '../storage/StorageService';
import { sortUsers } from './misc';
import env from '../../configs/env';
import { buildInfoLink } from '../../services/link';

class UserService extends KeyValueStorageService<string | null> {
	#hasLoggedInCookie = cookie.parse(document.cookie)?.amsLoggedIn === 'true';
	#idToken: string | null = null;
	#claims: AnyObject | null = null;
	#users: Map<string, User> = new Map<User['uid'], User>();
	#listeners: Set<() => void> = new Set();

	#subscribeIdTokenChanged(): Promise<void> {
		// wait for idToken
		return new Promise((resolve) => {
			onIdTokenChanged(auth, async (user: FirebaseUser | null) => {
				if (user) {
					this.#idToken = await user.getIdToken();
					this.#claims = (await user.getIdTokenResult()).claims;
					if (!this.#claims?.firebase?.identities?.[LOGIN_TYPES.OIDC_ZDF]) {
						this.email = user.email;
					}
				} else {
					this.#idToken = null;
					this.#claims = null;
				}

				resolve();
			});
		});
	}

	async init() {
		await super.init();
		await auth.authStateReady();

		try {
			await this.#handleLogin();
			await this.#subscribeIdTokenChanged();

			if (this.userId) {
				const users = await fetchUsers();
				users && this.#storeUsers(users);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async #handleLogin() {
		let customToken;

		if (window.location.hash.includes('customToken=')) {
			const searchParamsWithoutLeadingHash = window.location.hash.substring(1);
			this.#removeLoginData();
			const urlSearchParams = new URLSearchParams(searchParamsWithoutLeadingHash);
			customToken = urlSearchParams.get('customToken');
		} else if (defaultState.navigation.idToken) {
			customToken = await fetchCustomTokenByIdToken(defaultState.navigation.idToken);
		} else if (this.#hasLoggedInCookie) {
			if (!auth.currentUser) {
				customToken = await fetchCustomTokenByCookies();
			}
		} else {
			await auth.signOut();
		}

		await this.#loginWithCustomToken(customToken);
	}

	#removeLoginData() {
		const urlWithoutHash = window.location.pathname + window.location.search;
		window.history.replaceState('', '', urlWithoutHash);
	}

	async #loginWithCustomToken(customToken?: string | null) {
		if (customToken) {
			await signInWithCustomToken(auth, customToken);
		}
	}

	async loginWithPassword(email: string, password: string): Promise<FirebaseUser> {
		const { user } = await signInWithEmailAndPassword(auth, email, password);
		this.email = email;

		return user;
	}

	async loginWithOAuthProvider(providerType: LOGIN_TYPES) {
		const provider = new OAuthProvider(providerType);
		provider.setCustomParameters({
			prompt: 'login',
		});
		const loginData = await signInWithPopup(auth, provider);

		if (!loginData.user.email) {
			const additionalUserInfo = getAdditionalUserInfo(loginData);
			const providerName = additionalUserInfo?.providerId?.replace('oidc.', '');
			await updateEmail(loginData.user, `${providerName}.nutzer@${additionalUserInfo?.profile?.sub}.ext`);
		}

		await updateProfile(loginData.user, {
			photoURL: Avatars[0],
		});
	}

	async changePassword(oldPassword: string, newPassword: string) {
		if (this.email) {
			const { user } = await signInWithEmailAndPassword(auth, this.email, oldPassword);
			await updatePassword(user, newPassword);
		}
	}

	#storeUsers(users: Array<User>) {
		users.forEach((user) => {
			this.#users.set(user.uid, user);
		});
	}

	#setUserAndSort(user: User) {
		this.#users.set(user.uid, user);
		const sortedUsers = sortUsers(Array.from(this.#users.values()), this.userId);
		this.#users.clear();
		this.#storeUsers(sortedUsers);
	}

	async editUser(userId: string, imageId?: number, displayName?: string) {
		const updatedUser = await fetchAddOrEditUser(imageId, displayName, userId);
		this.#setUserAndSort(updatedUser);
	}

	async deleteMainUser() {
		await fetchDeleteUsers();
		await this.clearItems(true);
		window.location.href = `${env.HOST}/sso/logout?redirect_uri=${env.HOST + buildInfoLink(INFO_TYPES.ACCOUNT_DELETED)}`;
	}

	async addSubUser(imageId?: number, displayName?: string) {
		const addedSubUser = await fetchAddOrEditUser(imageId, displayName);
		this.#setUserAndSort(addedSubUser);
	}

	async deleteSubUser(subUserId: string) {
		await fetchDeleteUsers(subUserId);
		this.#users.delete(subUserId);
	}

	isProfileNameUnique(newUserName: string, userId?: string): boolean {
		let isUnique = true;
		this.#users.forEach((user) => {
			if (user.displayName.trim().toLowerCase() === newUserName.trim().toLowerCase()) {
				isUnique = user.uid === userId;
			}
		});
		return isUnique;
	}

	async clearItems(removeEmail?: boolean): Promise<void> {
		if (removeEmail) {
			await super.clearItems();
		} else {
			await super.clearItemsWithExcludes([StorageKeys.EMAIL]);
		}
	}

	async refreshIdToken(): Promise<string | null> {
		const idToken = (await auth.currentUser?.getIdToken(true)) ?? null;
		this.#triggerUserDataChange();
		return idToken;
	}

	async #getCustomToken(): Promise<string | null> {
		return (this.idToken && (await fetchCustomTokenByIdToken(this.idToken))) ?? null;
	}

	get refreshToken(): string | undefined {
		return auth.currentUser?.refreshToken;
	}

	get users(): Map<User['uid'], User> {
		return this.#users;
	}

	get email(): string | null {
		return this.getItem(StorageKeys.EMAIL)?.replace(' ', '+') ?? null;
	}

	set email(email: string | null) {
		this.addItem(StorageKeys.EMAIL, email);
	}

	get customToken(): Promise<string | null> {
		return this.#getCustomToken();
	}

	get idToken(): string | null {
		return this.#idToken;
	}

	/**
    Only at AMS: Additional mail confirmation check for main users to route and redirect the user correctly only on mail confirmation.
    SubUsers & ZDF accounts are excluded because they have no mail and therefore no verification/confirmation.
   	*/
	/** Returns the id of current user. Can be the main user id or a sub user id. */
	get userId(): string | null {
		if (auth.currentUser) {
			if (this.isSubUser || this.loginType === LOGIN_TYPES.OIDC_ZDF) {
				return auth.currentUser.uid;
			} else if (auth.currentUser.emailVerified && auth.currentUser.uid) {
				return auth.currentUser.uid;
			}
			return null;
		}
		return null;
	}

	/** Returns the main user id. When current user is main user, then userId & accountId are equal. */
	get accountId(): string | null {
		return this.#claims?.mainUserId ?? this.userId;
	}

	get profileName(): string {
		return auth.currentUser?.displayName ?? DEFAULT_USER.displayName;
	}

	get profileImage(): string {
		return auth.currentUser?.photoURL ?? Avatars[DEFAULT_USER.imageId];
	}

	get loginType(): LOGIN_TYPES | undefined {
		return this.#claims?.firebase?.identities?.[LOGIN_TYPES.OIDC_ZDF] ? LOGIN_TYPES.OIDC_ZDF : LOGIN_TYPES.DEFAULT;
	}

	get ageRating(): number | undefined {
		return this.#claims?.age_rating;
	}

	get pin(): string | null {
		return this.#claims?.pin ?? null;
	}

	get isAgeVerified(): boolean {
		return !!this.ageRating && !!this.pin;
	}

	get isSubUser(): boolean {
		return !!this.#claims?.mainUserId;
	}

	// register subscriber for useSyncExternalStore
	subscribe = (listener: () => void) => {
		this.#listeners.add(listener);
		return () => {
			this.#listeners.delete(listener);
		};
	};

	// triggers subscribers of useSyncExternalStore
	#triggerUserDataChange = () => {
		for (const listener of this.#listeners) {
			listener();
		}
	};
}

const userService = new UserService(IDB_STORE_TYPES.USER);
export default userService;
