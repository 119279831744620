import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { LogoWrapper, Navigation, Root } from './styles';
import { BackIcon, Button, ChannelLogo, ContextMenu2, Image, LogoutIcon } from '@ard-online/component-styleguide';
import { MESSAGE_TYPES, MODE_TYPES } from '../../../configs/types';
import { buildLogoutLink } from '../../../services/link';
import { useContextActions } from '../../../services/actions/ActionsService';
import userService from '../../../services/user/UserService';
import { redirectToRefererApp } from '../../../services/misc';
import { cx } from '@linaria/core';
import { Props as ContextMenuProps } from '@ard-online/component-styleguide/dist/components/ContextMenu2/ContextMenu2';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function getDomainNameWithoutSubdomain(url?: string) {
	if (url) {
		const urlParts = new URL(url).hostname.split('.');
		return urlParts
			.slice(0)
			.slice(-(urlParts.length === 4 ? 3 : 2))
			.join('.');
	}
}

const contextMenuItems: ContextMenuProps['items'] = [
	{
		text: 'Abmelden',
		type: BUTTON_TYPES.TAB,
		href: buildLogoutLink(),
		iconLeft: <LogoutIcon />,
	},
];

function ToolbarWidget() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	const backButtonText = useMemo(() => {
		if (appState.config.modeType === MODE_TYPES.OIDC) {
			return 'zurück';
		}
		const redirectDomainName = getDomainNameWithoutSubdomain(appState.navigation.redirectUrl);
		return redirectDomainName ? `Zu ${redirectDomainName}` : undefined;
	}, [appState.navigation.redirectUrl]);
	const isUserCodeLogin = !!useMatch('/id/*');

	async function onBackButtonClick() {
		if (appState.config.modeType === MODE_TYPES.OIDC) {
			navigate(-1);
		} else {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.BACK_TO_REFERER_APP);
		}
	}

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<div className={cx(Navigation.base)}>
				<div className={cx(LogoWrapper.base)}>
					<ChannelLogo channel="ard" />
				</div>
				{backButtonText && (
					<Button
						text={backButtonText}
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						onClick={onBackButtonClick}
					/>
				)}
			</div>
			{userService.userId && !isUserCodeLogin && (
				<ContextMenu2 items={contextMenuItems}>
					<Button
						text="Profil"
						type={BUTTON_TYPES.TEXT}
						isIconOnly
						iconLeft={<Image src={userService.profileImage} />}
					/>
				</ContextMenu2>
			)}
		</div>
	);
}

export default ToolbarWidget;
