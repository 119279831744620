import { Link, useNavigate } from 'react-router-dom';
import { FormEvent, useState } from 'react';
import { Avatars, ErrorMessages, ProfileInfos } from '../../../configs/constants';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { INFO_TYPES, MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import { useTrackPageImpression } from '../../../services/tracking';
import { BackIcon, Button, H1 } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import userService from '../../../services/user/UserService';
import ProfileImageWidget from '../../widgets/profileImage/ProfileImageWidget';
import ProfileInfoWidget from '../../widgets/profileInfo/ProfileInfoWidget';
import ProfileNameWidget from '../../widgets/profileName/ProfileNameWidget';
import { CustomBox, Root } from './styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { redirectToRefererApp } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';

function AddProfilePage() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [profileName, setProfileName] = useState<string | null>('');
	const [profileImageId, setProfileImageId] = useState(0);

	const isSubmitAllowed = profileName?.trim() && Avatars?.[profileImageId];

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isSubmitAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				const isProfileNameUnique = userService.isProfileNameUnique(profileName || '');

				if (isProfileNameUnique) {
					await userService.addSubUser(profileImageId, profileName || '');
					navigate(buildInfoLink(INFO_TYPES.PROFILE_ADDED));
				} else {
					setMessage(ErrorMessages.UNIQUE_PROFILE_NAME);
					setLoading(false);
				}
			} catch (error) {
				console.error(error);
				setMessage(ErrorMessages.DEFAULT);
				setLoading(false);
			}
		}
	}

	async function onGoBack(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (appState.navigation.redirectUrl && appState.navigation.entryPageSlug !== PAGE_TYPES.EDIT_ACCOUNT) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.BACK_TO_REFERER_APP);
		} else {
			navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT));
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base, Box.type.medium, CustomBox.base)}>
				{!userService.isSubUser && (
					<div className={cx(BackButtonWrapper.base)}>
						<Button
							text="Zurück"
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							onClick={onGoBack}
							as={Link}
						/>
					</div>
				)}
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<h1 className={cx(H1.base)}>Profil erstellen</h1>
					<div className={cx(Mask.base)}>
						<ProfileNameWidget setProfileName={setProfileName} />
						<ProfileImageWidget profileImageId={profileImageId} setProfileImageId={setProfileImageId} />
					</div>
					<ProfileInfoWidget text={ProfileInfos.ADD_PROFILE_INFO} />
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text="Speichern"
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!isSubmitAllowed}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default AddProfilePage;
