import { FormEvent, useRef, useState } from 'react';
import { Headline, LoginArea, Root, Text, ZdfTermsOfUse } from './styles';
import { Button } from '@ard-online/component-styleguide';
import { Box, Form, Mask } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { isZdfOidcClient } from '../../../services/validation';
import { useContextActions } from '../../../services/actions/ActionsService';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function OidcLoginPage() {
	/** Variables */
	const { appState } = useContextActions();
	const isZdfOidcLogin = isZdfOidcClient(appState.navigation.clientId);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const [zdfTermsOfUseCheckbox, setZdfTermsOfUseCheckbox] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const idTokenInputRef = useRef<HTMLInputElement>(null);
	const oidcAuthUrl = `/oidc/authorize?sessionId=${appState.navigation.oidcState}`;
	const isUsingCurrentUser = userService.userId && email === userService.email;
	const submittable =
		((email && password) || isUsingCurrentUser) && (!isZdfOidcLogin || (isZdfOidcLogin && zdfTermsOfUseCheckbox));

	async function onSubmit(event: FormEvent) {
		if (event.nativeEvent) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			return;
		}

		if (submittable && !loading) {
			setLoading(true);
			setMessage(null);
			try {
				if (isUsingCurrentUser && idTokenInputRef.current) {
					idTokenInputRef.current.value = String(userService.idToken);
					formRef.current?.submit();
				} else {
					await loginWithEmailPassword(email as string, password);
					await sso(oidcAuthUrl);
				}
			} catch (error) {
				error instanceof Error && setMessage(error.message);
				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	// TODO 9/11/2023 esen: email anzeigen und möglichkeit account zu ändern
	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<h1 className={cx(Headline.base)}>
					{userService.email
						? STRINGS.oidcLoginPage.userEmailExists.headline
						: STRINGS.oidcLoginPage.userEmailMissing.headline}
				</h1>
				<div className={cx(LoginArea.base)}>
					<form
						className={cx(Form.base)}
						onSubmit={onSubmit}
						action={oidcAuthUrl}
						method="post"
						encType="application/x-www-form-urlencoded"
						ref={formRef}
					>
						<input type="hidden" name="idToken" ref={idTokenInputRef} />
						<div className={cx(Mask.base)}>
							<CredentialsWidget email={email} setEmail={setEmail} setPassword={setPassword} />
							{isZdfOidcLogin && (
								<label className={cx(ZdfTermsOfUse.base)}>
									<input
										name="zdfTermsOfUse"
										type="checkbox"
										checked={zdfTermsOfUseCheckbox}
										onChange={(e) => setZdfTermsOfUseCheckbox(e.target.checked)}
										title={STRINGS.oidcLoginPage.input.text}
									/>
									<div className={cx(Text.base)}>
										Ich akzeptiere die{' '}
										<a
											href="https://www.zdf.de/regeln-und-nutzungsbedingungen-100.html"
											target="_blank"
											rel="noopener noreferrer"
										>
											Nutzungsbedingungen
										</a>{' '}
										des ZDF und habe die{' '}
										<a href="https://www.zdf.de/dsgvo/" target="_blank" rel="noopener noreferrer">
											Datenschutzhinweise
										</a>{' '}
										zur Kenntnis genommen.
									</div>
								</label>
							)}
						</div>
						{message && <MessageWidget>{message}</MessageWidget>}
						<Button
							text="Anmelden"
							type={BUTTON_TYPES.PRIMARY}
							onClick={onSubmit}
							isDisabled={!submittable}
							isLoading={loading}
							htmlAttributes={{
								type: 'submit',
							}}
						/>
					</form>
				</div>
			</main>
			{ssoForm}
		</div>
	);
}

export default OidcLoginPage;
