import { cssEaseOut, P } from '@ard-online/component-styleguide';
import { Root as InputRoot } from '@ard-online/component-styleguide/dist/components/Input/styles';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { css } from '@linaria/core';

export const Root = {
	base: css`
		display: flex;
		min-height: 100vh;
		flex-direction: column;
		background-color: rgb(var(--global-background));
	`,
};

export const Box = {
	base: css`
		min-width: 400px;
		max-width: 400px;
		padding: 20px;
		padding-bottom: 40px;
		display: flex;
		flex-direction: column;
		text-align: center;
		position: relative;
		box-sizing: border-box;
		background-color: rgb(var(--global-background));
		will-change: transform, opacity;
		margin: 50px 0;

		@media only screen and (max-width: 380px) {
			max-width: 100vw;
			min-width: unset;
			width: 320px;
		}

		animation: fadeScaleIn 200ms ${cssEaseOut} both;
		@keyframes fadeScaleIn {
			0% {
				opacity: 0;
				transform: scale(0.8);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		.${InputRoot.base} {
			margin: 0 0 15px 0;
		}

		.${P.base} {
			text-align: left;
			margin-bottom: 0;
		}

		.${ButtonRoot.base} {
			align-self: center;
		}
	`,
	hasPromoboard: css`
		max-width: 720px;
		@media only screen and (max-width: 720px) {
			max-width: 360px;
		}
	`,
	type: {
		large: css`
			max-width: 660px;
			@media only screen and (max-width: 660px) {
				max-width: 100vw;
			}
		`,
		medium: css`
			max-width: 550px;
			@media only screen and (max-width: 550px) {
				max-width: 100vw;
			}
		`,
	},
};

export const Form = {
	base: css`
		display: flex;
		flex-direction: column;
		position: relative;
		box-sizing: border-box;
	`,
};

export const GlobalStyle = css`
	/* stylelint-disable-next-line */
	:global() {
		body {
			color: rgb(var(--global-text));
			margin: 0;
			font-family: 'The Sans', 'Thesis', sans-serif;
		}

		a {
			color: rgb(var(--global-text));
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}

		:active,
		:hover,
		:visited {
			outline: none;
		}

		:focus {
			outline: 2px solid rgb(var(--brand-dark-secondary));
			outline-offset: -2px;
		}

		hr {
			border: 0;
			width: 100%;
			border-top: 1px solid #e2e2e2;
			margin: 20px auto 10px;
		}
	}
`;

export const BoxWrapper = {
	base: css`
		display: flex;
		justify-content: center;
		flex: 1;

		h1 {
			text-align: left;
			padding: 0;
			font-size: 1.8rem;
		}
	`,
};

export const Logo = {
	base: css`
		svg {
			width: 60%;
			max-width: 167px;
			margin: 0 auto;
		}
	`,
};

export const Mask = {
	base: css`
		text-align: left;
		margin: 10px 0 20px;

		> .${P.base} {
			font-size: 0.9rem;
		}

		a {
			margin-top: -10px;
			display: block;
		}
	`,
};

export const BackButtonWrapper = {
	base: css`
		align-self: baseline;
		margin-bottom: 10px;
		margin-left: -15px;
	`,
};

export const RowAction = {
	base: css`
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: center;
		margin-bottom: 10px;
		width: 100%;
		overflow: hidden;

		> span {
			margin-left: 5px;
			margin-right: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		a {
			margin: 0;
			flex-shrink: 0;
		}
	`,
};
