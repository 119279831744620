import { FormEvent } from 'react';
import { PAGE_TYPES } from '../../../../configs/types';
import { Button, H1, P } from '@ard-online/component-styleguide';
import { Mask } from '../../../app/styles';
import { Link } from 'react-router-dom';
import { buildMainLink } from '../../../../services/link';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../../firebase';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { SentMailAgainButtonWrapper } from '../styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import getContinueUrl from '../../../../services/url/getContinueUrl';
import { useHashParams } from '../../../../services/misc';

function ResetPasswordMailSent() {
	const { appState } = useContextActions();
	const hashParams = useHashParams();
	const email = hashParams?.get('email') ?? '';

	async function onClick(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (email) {
			try {
				let actionCodeSettings;

				if (appState.navigation.redirectUrl) {
					actionCodeSettings = {
						url: getContinueUrl(appState),
					};
				}

				await sendPasswordResetEmail(auth, email, actionCodeSettings);
			} catch (error) {
				console.warn('Fehler', error);
			}
		}
	}

	/** JSX */
	return (
		<>
			<h1 className={cx(H1.base)}>Sie haben Post</h1>
			<div className={cx(Mask.base)}>
				<p className={cx(P.base)}>
					Eine E-Mail wurde an <strong>{email}</strong> verschickt. Bitte beachten Sie auch den Spam-Ordner.
				</p>
				<div className={cx(SentMailAgainButtonWrapper.base)}>
					<p className={cx(P.base)}>Keine E-Mail erhalten? </p>
					<Button text="E-Mail erneut senden" onClick={onClick} type={BUTTON_TYPES.TEXT} />
				</div>
			</div>
			<Button text="Abschließen" href={buildMainLink(PAGE_TYPES.START)} type={BUTTON_TYPES.PRIMARY} as={Link} />
		</>
	);
}

export default ResetPasswordMailSent;
