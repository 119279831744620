import { css } from '@linaria/core';
import { P } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 100vh;

		.${P.base} {
			color: rgb(var(--brand-dark-text-primary));
			font-size: 2rem;
		}
	`,
};
