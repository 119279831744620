import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { sendToTrackingProxy } from './index';
import { MODE_TYPES, TRACKING_PARAM_TYPES, TRACKING_TYPES } from '../../configs/types';
import { useContextActions } from '../actions/ActionsService';
import { PageImpressionTrackingEvent } from './types';
import settingsService from '../settings/SettingsService';

export default function useTrackPageImpression() {
	const { pathname, search } = useLocation();
	const { appState } = useContextActions();

	useEffect(() => {
		if (settingsService.trackingEnabled) {
			const { config, navigation } = appState;

			// set referer
			const trackingEvent: PageImpressionTrackingEvent = {
				[TRACKING_PARAM_TYPES.REFERER]: pathname + search,
			};

			// set identify platform & app to external referrer tracking param
			if (navigation.redirectUrl) {
				const { host } = new URL(navigation.redirectUrl);
				trackingEvent[TRACKING_PARAM_TYPES.EXTERNAL_REFERER] = host;
			} else if (config.modeType === MODE_TYPES.OIDC) {
				trackingEvent[TRACKING_PARAM_TYPES.EXTERNAL_REFERER] = navigation.clientId;
			}

			sendToTrackingProxy(TRACKING_TYPES.PAGE, trackingEvent);
		}
	}, []);
}
