import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const ProfilesWrapper = {
	base: css`
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		ul {
			list-style-type: none;
			padding: 0;

			li {
				display: flex;
				margin-bottom: 17px;
				position: relative;

				&:before {
					position: absolute;
					bottom: -9px;
					left: 0;
					content: '';
					display: block;
					height: 1px;
					width: 100%;
					background: rgba(var(--global-text-secondary), 0.2);
				}

				&:last-child {
					margin-bottom: 0;
					&:before {
						content: none;
					}
				}
			}
		}

		.${ButtonRoot.base} {
			width: 100%;
			text-align: left;
		}

		hr {
			opacity: 0.4;
			margin: 8px 0;
		}
	`,
};
