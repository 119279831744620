import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Root } from './styles';
import { BackIcon, Button, H1, Input, P } from '@ard-online/component-styleguide';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../../firebase';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import type { AnyFunction, AnyObject } from '../../../types';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import useUrlSearchParams from '../../../services/useUrlSearchParams';
import { useSso } from '../login/misc';
import { isGoogleApiError } from '../../../services/misc';
import PasswordWidget from '../../widgets/password/PasswordWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';

function NewPasswordPage() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();
	const searchParams = useUrlSearchParams();
	const oobCode = searchParams.get('oobCode');
	const [loading, setLoading] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const isForgotPasswordPage = oobCode;
	const isNextStepAllowed = (isForgotPasswordPage && newPassword) || (newPassword && oldPassword);

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage('');

			try {
				const email = userService.email;
				// Password reset case
				if (oobCode) {
					await confirmPasswordReset(auth, oobCode, newPassword);
					if (email) {
						await userService.loginWithPassword(email, newPassword);
						await sso(buildInfoLink(INFO_TYPES.PASSWORD_CHANGED, appState.navigation.redirectUrl));
					}
					// Password change case
				} else if (email) {
					await userService.changePassword(oldPassword, newPassword);
					await sso(buildInfoLink(INFO_TYPES.PASSWORD_CHANGED, appState.navigation.redirectUrl));
				}
			} catch (error) {
				console.error(error);

				if (isGoogleApiError(error) && error.code === GoogleApiCodes.WRONG_PASSWORD) {
					setMessage('Altes Passwort falsch eingegeben.');
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onOldPasswordChange(e: AnyObject) {
		if (e.isValid) {
			setOldPassword(e.value);
		} else {
			setOldPassword('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!oobCode && !userService.userId) {
			navigate(buildMainLink(PAGE_TYPES.START));
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					{!isForgotPasswordPage && (
						<div className={cx(BackButtonWrapper.base)}>
							<Button
								text="Zurück"
								type={BUTTON_TYPES.TEXT}
								iconLeft={<BackIcon />}
								href={buildMainLink(PAGE_TYPES.EDIT_ACCOUNT)}
								as={Link}
							/>
						</div>
					)}
					<h1 className={cx(H1.base)}>
						{isForgotPasswordPage
							? STRINGS.newPasswordPage.isForgotPasswordPage.headline
							: STRINGS.newPasswordPage.headline}
					</h1>
					<div className={cx(Mask.base)}>
						<p className={cx(P.base)}>
							{isForgotPasswordPage
								? STRINGS.newPasswordPage.isForgotPasswordPage.text
								: STRINGS.newPasswordPage.text}
						</p>
						<PasswordWidget onValidPassword={setNewPassword} inputLabel="Neues Passwort" />
						<br />
						{!oobCode && userService.userId && (
							<>
								<p className={cx(P.base)}>
									Bestätigen Sie die Änderung mit Ihrem bestehenden Passwort.
								</p>
								<Input
									id="oldPasswordInput"
									type="password"
									label="Altes Passwort"
									isRequired
									errorMessage="Kein valides Passwort"
									onChange={onOldPasswordChange}
									htmlAttributes={{
										autoComplete: 'current-password',
										title: 'Altes Passwort eingeben',
									}}
								/>
							</>
						)}
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text={isForgotPasswordPage ? 'Weiter' : 'Passwort ändern'}
						type={BUTTON_TYPES.PRIMARY}
						isDisabled={!isNextStepAllowed}
						onClick={onSubmit}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
				{ssoForm}
			</main>
		</div>
	);
}

export default NewPasswordPage;
