import { redirectToRefererApp } from '../../../../services/misc';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { buildMainLink } from '../../../../services/link';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../../configs/types';
import { Button, H1, P } from '@ard-online/component-styleguide';
import { Mask } from '../../../app/styles';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonsWrapper } from '../styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function AccountDeleted() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	async function onClick() {
		if (appState.navigation.redirectUrl) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.ACCOUNT_DELETED);
		} else {
			navigate(buildMainLink(PAGE_TYPES.START));
		}
	}

	/** JSX */
	return (
		<>
			<h1 className={cx(H1.base)}>Schade, dass Sie gehen!</h1>
			<div className={cx(Mask.base)}>
				<p className={cx(P.base)}>
					Ihr ARD-Konto und alle Profile wurden gelöscht. Sie können sich natürlich jederzeit neu
					registrieren. Bis bald!
				</p>
			</div>
			<div className={cx(ButtonsWrapper.base)}>
				<Button
					text="Ein ARD-Konto erstellen"
					href={buildMainLink(PAGE_TYPES.REGISTRATION)}
					type={BUTTON_TYPES.SECONDARY}
					as={Link}
				/>
				<Button text="Fertig" onClick={onClick} type={BUTTON_TYPES.PRIMARY} as={Link} />
			</div>
		</>
	);
}

export default AccountDeleted;
