const HOST = `http://localhost:${ENVIRONMENT_TYPE === ENVIRONMENT_TYPES.SERVER ? 3011 : 3010}`;

const localEnv = {
	STAGE_TYPE: 'local',
	HOST: HOST,
	SSO_CLIENT_HOSTS: [HOST], // first must be AMS
	FIREBASE: {
		apiKey: 'AIzaSyCP6MlzuRYhgg1Jtvc6odio9vqyJ20M274',
		authDomain: 'ardmt-local.web.app',
		projectId: 'ardmt-local',
		//messagingSenderId: '843114540747',
		appId: '1:843114540747:web:493b256ffb10d0c184f54e',
	},
	FIREBASE_TOKEN_KEY: 'AIzaSyDDC0aWh0erQSN1lUE0EEA0HUq_cOG_q3w',
	TRACKING_PROXY_HOST: 'http://localhost:3050/t',
	PIANO_ENVIRONMENT_ID: '634415',
};

export default localEnv;
