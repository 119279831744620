const HOST = 'https://accounts-beta.ard.de';

const betaEnv = {
	STAGE_TYPE: 'beta',
	HOST: HOST,
	SSO_CLIENT_HOSTS: [HOST, 'https://sso-beta.ardmediathek.de', 'https://sso-beta.ardaudiothek.de'], // first must be AMS
	FIREBASE: {
		apiKey: 'AIzaSyDS-simxm3F3NVTx3_IWADYSbhxrMCHZFs',
		authDomain: 'ardmt-ifa.web.app',
		projectId: 'ardmt-ifa',
		//messagingSenderId: '785022050191',
		appId: '1:785022050191:web:f3efceb0aa70edb74c35fa',
	},
	FIREBASE_TOKEN_KEY: 'AIzaSyA64ozISJkdZ23YsqcIIObDpjKaHw9tn2k',
	TRACKING_PROXY_HOST: 'https://accounts-beta.ard.de/t',
	PIANO_ENVIRONMENT_ID: '634416',
};

export default betaEnv;
