import { getRefreshUrl } from '../url';
import { MESSAGE_TYPES } from '../../configs/types';

export default function buildRedirectLink(
	redirectUrl: string,
	messageType: MESSAGE_TYPES,
	idToken?: string | null,
	refreshToken?: string | null,
	customToken?: string | null,
	showAccountMenu?: boolean,
) {
	try {
		const url = new URL(redirectUrl);

		if (showAccountMenu) {
			url.searchParams.append('showAccountMenu', '');
		}

		url.hash = `type=${messageType}`;

		if (idToken && refreshToken && customToken) {
			url.hash += `&idToken=${idToken}&refreshToken=${refreshToken}&refreshUrl=${getRefreshUrl()}&customToken=${customToken}`;
		}

		return url.href;
	} catch (error) {
		throw error;
	}
}
