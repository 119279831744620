import { useState, useEffect } from 'react';
import { amsDarkTheme, amsLightTheme, FontSizes, FontStyles } from '@ard-online/component-styleguide';
import { Root, BoxWrapper, GlobalStyle } from './styles';
import ToolbarWidget from '../widgets/toolbar/ToolbarWidget';
import { useContextActions } from '../../services/actions/ActionsService';
import { MODE_TYPES, THEME_TYPES } from '../../configs/types';
import AppRoutes from './routes';
import FooterWidget from '../widgets/footer/FooterWidget';
import 'swiper/css/bundle';
import { LinariaClassName, cx } from '@linaria/core';

type Theme = {
	id: string;
	name: string;
	base: LinariaClassName;
};

function getTheme(themeType: THEME_TYPES) {
	switch (themeType) {
		case THEME_TYPES.DARK:
			return amsDarkTheme;
		case THEME_TYPES.LIGHT:
			return amsLightTheme;
		case THEME_TYPES.DEFAULT:
			if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				return amsDarkTheme;
			} else {
				return amsLightTheme;
			}
		default:
			return amsLightTheme;
	}
}

function App() {
	/** Variables */
	const { appState } = useContextActions();
	const [theme, setTheme] = useState(() => getTheme(appState.view.theme));

	function changeTheme(newTheme: Theme) {
		setTheme((prevTheme: Theme) => {
			if (prevTheme?.base !== newTheme?.base) {
				document.body.classList.remove(prevTheme?.base);
				document.body.classList.add(newTheme?.base);
			}
			return newTheme;
		});
	}

	useEffect(() => {
		document.body.classList.add(theme?.base);
	}, []);

	useEffect(() => {
		changeTheme(getTheme(appState.view.theme));
	}, [appState.view.theme]);

	/** JSX */
	return (
		<div className={cx(Root.base, cx(FontSizes, FontStyles, GlobalStyle))}>
			{appState.config.modeType !== MODE_TYPES.IN_APP && <ToolbarWidget />}
			<div className={cx(BoxWrapper.base)}>
				<AppRoutes />
			</div>
			<FooterWidget />
		</div>
	);
}

export default App;
