import { AppState } from '../../configs/defaultState';
import env from '../../configs/env';

export default function getContinueUrl(appState: AppState, email?: string | null) {
	const url = new URL(env.HOST);
	appState.navigation.redirectUrl && url.searchParams.append('redirect_uri', appState.navigation.redirectUrl);

	if (email) {
		if (url.hash) {
			url.hash += '&';
		}
		url.hash += `email=${email}`;
	}

	return url.href;
}
