import { css } from '@linaria/core';
import { P } from '@ard-online/component-styleguide';

// export const Root = styled.div<{ type: 'success' | 'error' }>`
export const Root = {
	base: css`
		background-color: rgba(var(--global-error), 0.7);
		border: 2px solid rgb(var(--global-error));
		padding: 5px 10px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 4px;

		.${P.base} {
			color: rgb(var(--brand-dark-text-primary));
			font-size: 0.85rem;
			margin: 0 !important;
			text-align: left !important;
			letter-spacing: 0.3px;
		}

		svg {
			fill: rgb(var(--brand-dark-text-primary));
			width: 15%;
			float: left;
			margin: -2% -2% -2% -5%;
			min-width: 58px;
		}
	`,
	type: {
		success: css`
			background-color: #67b277;
			border: 2px solid #22913b;
		`,
		error: null,
	},
};
