import { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Root } from './styles';
import { BackIcon, Button, H1, Input, P } from '@ard-online/component-styleguide';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { isValidEmail } from '../../../services/validation';
import MessageWidget from '../../widgets/message/MessageWidget';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { NavigateFunction } from 'react-router';
import { isGoogleApiError, isTouchDevice, useHashParams } from '../../../services/misc';
import { AnyObject } from '../../../types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import getContinueUrl from '../../../services/url/getContinueUrl';

function navigateOnSuccess(navigate: NavigateFunction, email: string) {
	navigate(`${buildInfoLink(INFO_TYPES.RESET_PASSWORD_MAIL_SENT)}#email=${email}`);
}

function ForgotPasswordPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const hashParams = useHashParams();
	const emailParam = hashParams?.get('email') ?? '';
	const [email, setEmail] = useState(emailParam);
	const [message, setMessage] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (email && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				let actionCodeSettings;

				if (appState.navigation.redirectUrl) {
					actionCodeSettings = {
						url: getContinueUrl(appState),
					};
				}

				await sendPasswordResetEmail(auth, email, actionCodeSettings);
				navigateOnSuccess(navigate, email);
			} catch (error) {
				if (isGoogleApiError(error) && error.code === GoogleApiCodes.USER_NOT_FOUND) {
					navigateOnSuccess(navigate, email);
				} else {
					console.error(error);
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<div className={cx(BackButtonWrapper.base)}>
					<Button
						text="Zurück"
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						href={buildMainLink(PAGE_TYPES.EDIT_ACCOUNT)}
						as={Link}
					/>
				</div>
				<h1 className={cx(H1.base)}>Passwort vergessen?</h1>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<div className={cx(Mask.base)}>
						<p className={cx(P.base)}>{STRINGS.forgotPasswordPage.form.text}</p>
						<Input
							type="email"
							label="E-Mail-Adresse"
							isRequired
							errorMessage="Keine valide Email-Adresse"
							initialValue={emailParam}
							onChange={onInputChange}
							validation={isValidEmail}
							hasFocus={!isTouchDevice()}
							htmlAttributes={{
								autoComplete: 'email',
								title: 'E-Mail-Adresse eingeben',
							}}
						/>
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text={STRINGS.forgotPasswordPage.form.button}
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!email}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default ForgotPasswordPage;
