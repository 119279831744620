import local from './env.local';
import dev from './env.dev';
import test from './env.test';
import beta from './env.beta';
import prod from './env.prod';
import { AnyObject } from '../../types';

type EnvConfig = {
	[key: string]: AnyObject | string | number;
	STAGE_TYPE: string;
	HOST: string;
	SSO_CLIENT_HOSTS: Array<string>;
	FIREBASE: {
		apiKey: string;
		authDomain: string;
		projectId: string;
		//messagingSenderId: string;
		appId: string;
	};
	FIREBASE_TOKEN_KEY: string;
	TRACKING_PROXY_HOST: string;
	PIANO_ENVIRONMENT_ID: string;
};

function getEnv(stageType: string): EnvConfig {
	switch (stageType) {
		case STAGE_TYPES.DEV:
			return dev;

		case STAGE_TYPES.TEST:
			return test;

		case STAGE_TYPES.BETA:
			return beta;

		case STAGE_TYPES.PROD:
			return prod;

		default:
			return local;
	}
}

function setFromProcessEnv() {
	// somehow we are not able to override create-react-app eslint config which doesn't known our globals
	// without this the build will fail
	const env =
		ENVIRONMENT_TYPE === ENVIRONMENT_TYPES.SERVER // eslint-disable-line no-undef
			? getEnv(process.env.STAGE_TYPE || '')
			: getEnv((window as AnyObject).__config__.STAGE_TYPE);

	for (const key in env) {
		const envVar = process.env[key];
		if (envVar) {
			env[key] = envVar;
		}
	}
	return env;
}

export default setFromProcessEnv();
