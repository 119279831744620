import { Checkmark, Root } from './styles';
import { Checkmark2Icon, P } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

function PromoBoardWidget() {
	/** Variables */

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<ul>
				<li>
					<div className={cx(Checkmark.base)}>
						<Checkmark2Icon />
					</div>
					<p className={cx(P.base)}>{STRINGS.promoBoardWidget.list_1.text}</p>
				</li>
				<li>
					<div className={cx(Checkmark.base)}>
						<Checkmark2Icon />
					</div>
					<p className={cx(P.base)}>{STRINGS.promoBoardWidget.list_2.text}</p>
				</li>
				<li>
					<div className={cx(Checkmark.base)}>
						<Checkmark2Icon />
					</div>
					<p className={cx(P.base)}>{STRINGS.promoBoardWidget.list_3.text}</p>
				</li>
				<li>
					<div className={cx(Checkmark.base)}>
						<Checkmark2Icon />
					</div>
					<p className={cx(P.base)}>{STRINGS.promoBoardWidget.list_4.text}</p>
				</li>
			</ul>
		</div>
	);
}

export default PromoBoardWidget;
