import { css } from '@linaria/core';
import { H1, H2, Media, P } from '@ard-online/component-styleguide';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const Root = {
	base: css`
		max-height: 100%;

		.${P.base} {
			margin-bottom: 10px;
		}
		.${P.base}:first-of-type {
			margin-top: 0;
			padding-top: 0;
		}
	`,
};

export const AccountSection = {
	base: css`
		.${ButtonRoot.base} {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.${ImageRoot.base} {
			width: 48px;
			height: 48px;
			background: none;
		}
	`,
};

export const CustomBox = {
	base: css`
		width: 720px;

		.${H1.base} {
			padding-bottom: 24px;
		}

		.${H2.base} {
			font-weight: 600;
			text-align: left;
			padding: 0;
			font-size: 1.2rem;
		}

		.${P.base} {
			text-align: left;
			a:not([data-type]) {
				display: inline-block;
				text-decoration: underline;
			}
		}
	`,
};

export const CustomColumn = {
	base: css`
		text-align: left;
		a:not([data-type]) {
			display: inline-block;
			text-decoration: underline;
		}
	`,
	type: {
		link: css`
			${Media('min', 's')} {
				text-align: right !important;
			}

			a[data-type] {
				${Media('min', 's')} {
					float: right;
				}
			}

			a:not([data-type]) {
				text-decoration: underline;
			}
		`,
		check: css`
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			${Media('min', 's')} {
				margin-bottom: 0;
			}
		`,
	},
};

export const Checkmark = {
	base: css`
		width: 16px;
		height: 16px;
		background: #fff;
		z-index: 1;
		position: relative;
		flex-shrink: 0;
		border-radius: 50%;
		pointer-events: none;
		margin-right: 8px;

		svg {
			fill: #83cf92 !important;
			position: absolute;
			top: -12px;
			right: -12px;
			width: 40px !important;
			height: 40px !important;
		}
	`,
};

export const BreakParagraph = {
	base: css`
		hyphens: auto;
	`,
};

export const PasswordWrapper = {
	base: css`
		${Media('min', 's')} {
			padding-bottom: 0;
			margin-bottom: 0 !important;
		}
	`,
};

export const PinRow = {
	base: css`
		margin-bottom: 0;
		margin-top: 20px !important;

		${Media('min', 's')} {
			padding-top: 0;
			margin-top: 16px !important;
		}
	`,
};

export const ProfilesSection = {
	base: css`
		margin-bottom: 50px;
	`,
};

export const ProfilesWrapper = {
	base: css`
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		ul {
			list-style-type: none;
			padding: 0;

			li {
				display: flex;
				margin-bottom: 17px;
				position: relative;

				&:before {
					position: absolute;
					bottom: -9px;
					left: 0;
					content: '';
					display: block;
					height: 1px;
					width: 100%;
					background: rgba(var(--global-text-secondary), 0.2);
				}

				&:last-child {
					margin-bottom: 0;
					&:before {
						content: none;
					}
				}
			}
		}

		.${ButtonRoot.base} {
			width: 100%;
			text-align: left;
		}

		hr {
			opacity: 0.4;
			margin: 8px 0;
		}
	`,
};

export const AddProfileWrapper = {
	base: css`
		width: 100%;
		display: flex;
		justify-content: flex-end;
	`,
};
