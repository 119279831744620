import { H2, H3, H4, H5, H6, PageTitle } from '@ard-online/component-styleguide';
import { css } from '@linaria/core';
import { Box } from '../../app/styles';

export const Root = {
	base: css`
		min-height: 100vh;
		max-width: 960px;
		width: 100%;

		.${Box.base} {
			margin-top: 42px;
			max-width: unset;
			min-width: unset;
			width: 100%;
			hyphens: auto;

			.${H2.base}, .${H3.base}, .${H4.base}, .${H5.base}, .${H6.base} {
				text-align: left;
				margin-top: 1rem;
			}
		}

		.${PageTitle.base} {
			margin-top: 4px;
			margin-bottom: 21px;
		}
	`,
};

export { Box };
