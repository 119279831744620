import { Root } from './styles';
import { Loading } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

function LoadingIndicatorWidget() {
	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<div
				style={{
					height: '100vh',
				}}
			>
				<Loading />
			</div>
		</div>
	);
}

export default LoadingIndicatorWidget;
