import { css } from '@linaria/core';
import { Mask } from '../../app/styles';
import { Root as BadgeRoot } from '@ard-online/component-styleguide/dist/components/Badge/styles';
import { Media } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		max-height: 100%;
		h2 {
			font-weight: 600;
			text-align: left;
			padding: 0;
			font-size: 1.2rem;
		}
		.${Mask.base} {
			margin-bottom: 10px;
		}
	`,
};

export const IdImageWrapper = {
	base: css`
		overflow: hidden;
		width: 100%;
		position: relative;
		img {
			margin-top: 10px;
			width: 100%;
			min-height: 265px;
			@media only screen and (max-width: 500px) {
				min-height: 50vw;
			}
		}
	`,
};

export const InputWrapper = {
	base: css`
		position: relative;
		.${BadgeRoot.base} {
			position: absolute;
			top: 18px;
			transform: translateY(-50%);
			z-index: 2;
			font-size: 0.8rem;
			left: 10px;
			${Media('min', 'm')} {
				top: 19px;
			}
			${Media('min', 'xl')} {
				top: 21px;
			}
		}

		input {
			padding-left: 45px;
			padding-right: 64px;
			text-transform: uppercase;
		}
	`,
};

export const CardWrapper = {
	base: css`
		padding-top: 10px;
	`,
};

export const CardInfo = {
	base: css`
		display: flex;
		align-items: center;
		> svg {
			fill: rgb(var(--global-text)) !important;
			width: 40px !important;
			height: 40px !important;
		}
	`,
	isError: css`
		> svg {
			fill: rgb(var(--global-error)) !important;
		}
		p {
			color: rgb(var(--global-error));
		}
	`,
};

export const PinInputWrapper = {
	base: css`
		width: 60%;
		min-width: 200px;
		margin: 20px auto;
		input {
			height: 60px;
		}
	`,
};
