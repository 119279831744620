import { FormEvent, useState } from 'react';
import { Root, SentMailAgainButtonWrapper } from './styles';
import { Box } from '../../app/styles';
import { Button, H1, P } from '@ard-online/component-styleguide';
import { useNavigate } from 'react-router-dom';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { auth } from '../../../firebase';
import MessageWidget from '../../widgets/message/MessageWidget';
import { AnyFunction } from '../../../types';
import { ErrorMessages } from '../../../configs/constants';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function RegistrationMailSentPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [isLoading, setIsLoading] = useState(false);
	const [messageType, setMessageType]: ['error' | 'success' | undefined, AnyFunction] = useState('success');
	const [message, setMessage] = useState('');

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		navigate(buildMainLink(PAGE_TYPES.START));
	}

	async function onSendAgainClick() {
		setIsLoading(true);
		const user = auth.currentUser;
		try {
			if (user) {
				await sendEmailVerification(user, appState);
				setMessageType('success');
				setMessage('E-Mail wurde erfolgreich versendet.');
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setMessageType('error');
				setMessage(ErrorMessages.DEFAULT);
				throw Error('No User Account found');
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setMessageType('error');
			setMessage(ErrorMessages.DEFAULT);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<p className={cx(P.base)}>Schritt 4 von 4</p>
				<h1 className={cx(H1.base)}>Fast geschafft!</h1>
				<p className={cx(P.base)}>
					Eine E-Mail wurde an <b>{appState.registration.email}</b> verschickt.{' '}
					<b>Bitte beachten Sie auch den Spam-Ordner.</b>
				</p>
				<div className={cx(SentMailAgainButtonWrapper.base)}>
					<p className={cx(P.base)}>Keine E-Mail erhalten?</p>
					<Button
						text={'E-Mail erneut senden'}
						type={BUTTON_TYPES.TEXT}
						isLoading={isLoading}
						onClick={onSendAgainClick}
					/>
				</div>
				{message && <MessageWidget type={messageType}>{message}</MessageWidget>}
				<Button
					text={STRINGS.registrationMailSentPage.form.submit.button}
					type={BUTTON_TYPES.PRIMARY}
					onClick={onSubmit}
					htmlAttributes={{
						type: 'submit',
					}}
				/>
			</main>
		</div>
	);
}

export default RegistrationMailSentPage;
