import userService from '../../../services/user/UserService';
import { ArrowRightIcon, Button, Column, Image } from '@ard-online/component-styleguide';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Link } from 'react-router-dom';
import { cx } from '@linaria/core';
import { ProfilesWrapper } from './styles';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function ProfileListWidget() {
	const users = Array.from(userService.users.values());

	return users.length ? (
		<Column all={12} p={0} className={cx(ProfilesWrapper.base)}>
			<ul>
				{users.map((profile) => (
					<li key={profile.uid}>
						<Button
							text={`${profile.displayName}${profile.isSubUser ? '' : ' • Hauptprofil'}`}
							type={BUTTON_TYPES.SQUARE}
							iconLeft={
								<Image src={profile.photoURL} alt={`Profilbild von ${profile.displayName}`} isRound />
							}
							iconRight={<ArrowRightIcon />}
							isActive={profile.uid === userService.userId}
							href={`${buildMainLink(PAGE_TYPES.EDIT_PROFILE)}/${profile.uid}`}
							as={Link}
						/>
					</li>
				))}
			</ul>
		</Column>
	) : null;
}

export default ProfileListWidget;
