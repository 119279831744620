import { css } from '@linaria/core';
import { Root as SelectRoot } from '@ard-online/component-styleguide/dist/components/Select/styles';
import { Media, P, PageTitle } from '@ard-online/component-styleguide';
import { SwiperContainer } from '@ard-online/component-styleguide/dist/components/Swiper/styles';

export const Root = {
	base: css`
		padding-top: 70px;
	`,
};

export const List = {
	base: css`
		list-style: none;
		display: block;
		padding: 0;
		margin: 0;
	`,
};

// styled.li
export const Listitem = {
	base: css`
		list-style-type: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: 10px 0;
		margin: 0;
		border-bottom: 1px solid rgba(var(--global-text), 0.6);

		&:focus-within {
			background-color: rgba(var(--brand-dark-secondary), 0.3);
		}

		&:first-child {
			border-top: 1px solid rgba(var(--global-text), 0.6);
		}

		.${P.base} {
			margin-right: 10px;
			display: block;
		}

		span {
			color: rgb(var(--global-text));
			font-size: 0.8rem;
		}

		div[role='switch'] {
			flex-shrink: 0;
		}

		.${SelectRoot.base} {
			max-width: 180px;
		}
	`,
};

export const Page = {
	base: css`
		overflow: hidden; /* required for Swiper */
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		font-family: var(--fontFamily);
		padding-bottom: 150px;
		box-sizing: border-box;

		.${SwiperContainer.base} {
			margin-right: -16px;

			${Media('min', 'xs')} {
				margin-right: -32px;
			}

			${Media('min', 's')} {
				margin-right: -72px;
			}
		}

		.${PageTitle.base} {
			text-align: center;
		}
	`,
};
