import { buildLogoutLink } from '../../../../services/link';
import { Button, H1, H2, P } from '@ard-online/component-styleguide';
import { Mask } from '../../../app/styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { ZdfInfo } from '../styles';

function ZdfUser() {
	/** JSX */
	return (
		<>
			<h1 className={cx(H1.base)}>Konto-Einstellungen</h1>
			<div className={cx(Mask.base, ZdfInfo.base)}>
				<p className={cx(P.base)}>
					Sie sind mit Ihrem ZDF-Konto angemeldet. Auf{' '}
					<a href="https://www.zdf.de" target="_blank" rel="noreferrer">
						zdf.de
					</a>{' '}
					können Sie Ihr ZDF-Konto verwalten.
				</p>
				<p className={cx(P.base)}>
					Bitte beachten Sie: Das ZDF-Konto kann nicht für die Freischaltung gesperrter Inhalte verwendet
					werden. Die Altersbestätigung kann nur mit einem ARD-Konto durchgeführt werden.
				</p>
				<h2 className={cx(H2.base)}>Alter bestätigen?</h2>

				<p className={cx(P.base)}>
					Erstellen Sie ein ARD-Konto, um Inhalte mit Alterssperre rund um die Uhr abspielen zu können.
					Weitere Hinweise und Informationen rund um das ARD-Konto finden Sie auf:{' '}
					<a href="https://1.ard.de/login" target="_blank" rel="noreferrer">
						1.ard.de/login
					</a>
					.
				</p>
			</div>
			<Button text="ARD-Konto erstellen" type={BUTTON_TYPES.PRIMARY} href={buildLogoutLink()} />
		</>
	);
}

export default ZdfUser;
