import { FormEvent, useState } from 'react';
import { ButtonWrapper, Headline, LoginArea, LoginButtonWrapper, Root } from './styles';
import { Button, P } from '@ard-online/component-styleguide';
import { Box, Form, Mask } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useContextActions } from '../../../services/actions/ActionsService';
import { CLIENT_TYPES, LOGIN_TYPES, MODE_TYPES } from '../../../configs/types';
import env from '../../../configs/env';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import PromoBoardWidget from '../../widgets/promoBoard/PromoBoardWidget';
import useUrlSearchParams from '../../../services/useUrlSearchParams';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import CreateAccountButtonWidget from '../../widgets/createAccountButton/CreateAccountButtonWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function LoginPage() {
	/** Variables */
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const hasPromoBoard =
		!userService.email &&
		(appState.navigation.clientId === CLIENT_TYPES.MEDIATHEK ||
			appState.navigation.clientId === CLIENT_TYPES.AUDIOTHEK);
	const searchParams = useUrlSearchParams();
	const isEmailConfirmed = searchParams.has('emailConfirmed');
	const submittable = email && password;

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (submittable && !loading) {
			setLoading(true);
			setMessage(null);
			try {
				await loginWithEmailPassword(email, password);
				await sso();
			} catch (error) {
				error instanceof Error && setMessage(error.message);
				setLoading(false);
			}
		}
	}

	async function onClickZdf() {
		try {
			await userService.loginWithOAuthProvider(LOGIN_TYPES.OIDC_ZDF);
			await sso();
		} catch (error) {
			console.error(error);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	let headline = 'Mit dem ARD-Konto die Angebote der ARD noch bequemer nutzen';
	if (isEmailConfirmed) {
		headline = 'E-Mail-Bestätigung erfolgreich!';
	} else if (userService.email) {
		headline = 'Willkommen zurück';
	}

	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base, hasPromoBoard && Box.hasPromoboard)}>
				<h1 className={cx(Headline.base)}>{headline}</h1>
				{isEmailConfirmed && <p className={cx(P.base)}>{STRINGS.loginPage.isEmailConfirmed.text}</p>}
				{hasPromoBoard && <PromoBoardWidget />}
				<div className={cx(LoginArea.base, hasPromoBoard && LoginArea.hasPromoboard)}>
					<form className={cx(Form.base)} onSubmit={onSubmit}>
						<div className={cx(Mask.base)}>
							<CredentialsWidget email={email} setEmail={setEmail} setPassword={setPassword} />
						</div>
						{message && <MessageWidget>{message}</MessageWidget>}
						<div className={cx(LoginButtonWrapper.base)}>
							<Button
								text="Anmelden"
								type={BUTTON_TYPES.PRIMARY}
								onClick={onSubmit}
								isDisabled={!submittable}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</form>
					{ssoForm}
					{!userService.email && <CreateAccountButtonWidget />}
					{appState.config.modeType !== MODE_TYPES.IN_APP &&
						!isEmailConfirmed &&
						env.STAGE_TYPE !== STAGE_TYPES.BETA &&
						!userService.email && (
							<div className={cx(ButtonWrapper.base, ButtonWrapper.zdf)}>
								<Button
									text="Mit ZDF-Konto anmelden"
									type={BUTTON_TYPES.PRIMARY}
									onClick={onClickZdf}
								/>
							</div>
						)}
				</div>
			</main>
		</div>
	);
}

export default LoginPage;
