import type { IdentityCardData } from './verifyIdentityCardData';

const FILLER = '<';
const EMPTY_EXTRA = Array(14).fill(FILLER).join('');

let currentIndex = 0;
let inputString = '';

// see ICAO 9303-3 4.2.2.2 for details
// this parser additionally accepts whitespace between fields while adding missing trailing filler characters
function parseICAO9303Passport(str: string): IdentityCardData {
	inputString = str.trim();
	currentIndex = 0;

	const passportNumber = parsePassportNumber();
	const nationality = parseNationality();
	const dateOfBirth = parseDateOfBirth();
	parseSex();
	const dateOfExpiry = parseDateOfExpiry();
	const extra = parseExtra();

	const compositeCheckDigit = inputString.slice(-1);
	assertDigit(compositeCheckDigit);

	return {
		idNumber: passportNumber,
		birthday: dateOfBirth,
		checkCode: compositeCheckDigit,
		country: nationality,
		validity: dateOfExpiry,
		extra,
	};
}

function parsePassportNumber(): string {
	let passportNumber = '';

	while (passportNumber.length < 9) {
		passportNumber += consumeChar();
	}

	skipWhitespace();

	return passportNumber + parseCheckDigit();
}

function assertDigit(char: string) {
	if (!/\d/.test(char)) {
		throw Error(`Expected check digit to numeric but got '${char}'! position: '${currentIndex}'`);
	}
}

function parseCheckDigit(): string {
	const checkDigit = consumeChar();
	assertDigit(checkDigit);

	return checkDigit;
}

function parseNationality(): string {
	let nationality = '';

	while (nationality.length < 3) {
		nationality += consumeChar();
	}

	skipWhitespace();

	return nationality.replace(/</g, '');
}

function parseDateOfBirth(): string {
	let dateOfBirth = '';

	while (dateOfBirth.length < 6) {
		dateOfBirth += consumeChar();
	}

	return dateOfBirth + parseCheckDigit();
}

function parseSex(): string {
	const sex = consumeChar();

	skipWhitespace();

	return sex;
}

function parseDateOfExpiry(): string {
	let dateOfExpiry = '';

	while (dateOfExpiry.length < 6) {
		dateOfExpiry += consumeChar();
	}

	return dateOfExpiry + parseCheckDigit();
}

function parseExtra(): string {
	let extra = '';

	while (extra.length < 14) {
		extra += consumeChar();
	}

	skipWhitespace();

	/*
	quoted from spec:

	'''
	When the personal number field is not
	used and filler characters (<) are used
	in positions 29 to 42, the check digit
	may be zero or the filler character (<) at
	the option of the issuing State or
	organization.
	'''
	handle missing check digit when user inputs no extra field
 	*/
	if (extra === EMPTY_EXTRA) {
		return '';
	} else {
		extra += parseCheckDigit();
	}

	return extra;
}

function peekChar(): string {
	return inputString[currentIndex];
}

function consumeChar(): string {
	if (currentIndex >= inputString.length) {
		throw Error(`Expected more characters but reached end!`);
	}

	const currentChar = peekChar();
	if (currentChar === ' ') {
		return FILLER;
	}

	currentIndex += 1;
	return currentChar;
}

function skipWhitespace() {
	while (peekChar() === ' ') {
		currentIndex++;
	}
}

export default parseICAO9303Passport;
