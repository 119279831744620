export default async function fetchCustomTokenByIdToken(idToken: string): Promise<string | undefined> {
	try {
		const res = await fetch('/token', {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({ idToken }),
		});

		if (res.ok) {
			const { customToken } = await res.json();
			return customToken;
		} else {
			throw new Error(`Error fetching Custom Token by idToken | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		console.error(error);
	}
}
