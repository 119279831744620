import { PAGE_TYPES } from '../../../../configs/types';
import { Button, H1, P } from '@ard-online/component-styleguide';
import { Mask } from '../../../app/styles';
import { Link } from 'react-router-dom';
import { buildMainLink } from '../../../../services/link';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function ErrorLinkInvalid() {
	/** JSX */
	return (
		<>
			<h1 className={cx(H1.base)}>Link nicht gültig</h1>
			<div className={cx(Mask.base)}>
				<p className={cx(P.base)}>Dieser Link ist leider abgelaufen oder wurde bereits benutzt.</p>
			</div>
			<Button text="Okay" href={buildMainLink(PAGE_TYPES.START)} type={BUTTON_TYPES.PRIMARY} as={Link} />
		</>
	);
}

export default ErrorLinkInvalid;
