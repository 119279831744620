function isInObject<T>(Enum: T, value: unknown): value is T[keyof T] {
	for (const key in Enum) {
		if (Enum[key] === value) {
			return true;
		}
	}

	return false;
}

export default isInObject;
