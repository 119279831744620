import { useEffect } from 'react';
import LoadingIndicatorWidget from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { auth } from '../../../firebase';
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { useContextActions } from '../../../services/actions/ActionsService';
import useUrlSearchParams from '../../../services/useUrlSearchParams';
import userService from '../../../services/user/UserService';
import { useHashParams } from '../../../services/misc';

function EmailActionsPage() {
	/** Variables */
	const navigate = useNavigate();
	const { setRedirectUrl } = useContextActions();
	const searchParams = useUrlSearchParams();
	const hashParams = useHashParams();
	const mode = searchParams.get('mode');
	const oobCode = searchParams.get('oobCode') || '';
	const continueUrl = searchParams.get('continueUrl');
	const email = hashParams?.get('email');

	if (continueUrl) {
		const searchParams = new URL(continueUrl).searchParams;
		setRedirectUrl(searchParams.get('redirect_uri') ?? '');
	}

	async function emailActions() {
		try {
			if (email) {
				userService.email = email;
			}
			if (mode === 'verifyEmail') {
				await applyActionCode(auth, oobCode);
				navigate(`${buildMainLink(PAGE_TYPES.START)}?emailConfirmed`, { replace: true });
			} else if (mode === 'resetPassword') {
				await verifyPasswordResetCode(auth, oobCode);
				navigate(`/${PAGE_TYPES.NEW_PASSWORD}?oobCode=${oobCode}`, {
					replace: true,
				});
			}
		} catch (error) {
			console.error(error);
			navigate(buildInfoLink(INFO_TYPES.ERROR_LINK_INVALID), { replace: true });
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		emailActions();
	}, []);

	/** JSX */
	return <LoadingIndicatorWidget />;
}

export default EmailActionsPage;
