import { oidcRedirectWhitelist, redirectWhitelist } from '../../configs/whitelist';

export default function isRedirectAllowed(redirectUrl: string): boolean {
	try {
		return redirectWhitelist.some((rule) => rule.test(redirectUrl));
	} catch (error) {
		console.error(error);
		return false;
	}
}

export function isOidcRedirectAllowed(redirectUrl: string, clientId: string): boolean {
	try {
		return oidcRedirectWhitelist[clientId].some((rule) => rule.test(redirectUrl));
	} catch (error) {
		console.error(error);
		return false;
	}
}
