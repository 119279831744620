import { ChangeEvent, FormEvent, useState } from 'react';
import { Approval, Root, Text } from './styles';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { BackIcon, Button, H1, Input, P } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../../../services/validation';
import { isTouchDevice } from '../../../services/misc';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import type { AnyFunction, AnyObject } from '../../../types';
import { PAGE_TYPES } from '../../../configs/types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function RegistrationEmailPage() {
	/** Variables */
	const navigate = useNavigate();
	const { setRegistrationMail } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox]: [boolean, AnyFunction] = useState(false);
	const [termsOfUseCheckbox, setTermsOfUseCheckbox]: [boolean, AnyFunction] = useState(false);
	const isNextStepAllowed = email && privacyPolicyCheckbox && termsOfUseCheckbox;

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setRegistrationMail(email);
			navigate(PAGE_TYPES.REGISTRATION_PASSWORD);
		}
	}

	function _onPrivacyPolicyCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
		setPrivacyPolicyCheckbox(e.target.checked);
	}

	function _onTermsOfUseCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
		setTermsOfUseCheckbox(e.target.checked);
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<div className={cx(BackButtonWrapper.base)}>
						<Button
							text={STRINGS.registrationEmailPage.form.back.button}
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							href={buildMainLink(PAGE_TYPES.START)}
							as={Link}
						/>
					</div>
					<p className={cx(P.base)}>Schritt 1 von 4</p>
					<h1 className={cx(H1.base)}>E-Mail-Adresse</h1>
					<div className={cx(Mask.base)}>
						<Input
							type="email"
							label="E-Mail-Adresse"
							isRequired
							errorMessage="Keine valide E-Mail-Adresse"
							onChange={onInputChange}
							validation={isValidEmail}
							hasFocus={!isTouchDevice()}
							hasHintValidation
							htmlAttributes={{
								autoComplete: 'email',
								title: 'E-Mail-Adresse eingeben',
							}}
						/>
						<div className={cx(Approval.base)}>
							<label>
								<input
									type="checkbox"
									aria-label="Datenschutzerklärung"
									name="Datenschutzerklärung"
									value="Datenschutzerklärung"
									onChange={_onPrivacyPolicyCheckboxChange}
									title="Datenschutzerklärung zustimmen"
								/>
								<div className={cx(Text.base)}>
									Ich habe die <Link to={{ pathname: '/datenschutz' }}>Datenschutzerklärung</Link>{' '}
									vollständig gelesen und stimme ihr zu.
								</div>
							</label>
							<label>
								<input
									type="checkbox"
									name="Nutzungsbedingungen"
									aria-label="Nutzungsbedingungen"
									value="Nutzungsbedingungen"
									onChange={_onTermsOfUseCheckboxChange}
									title="Nutzungsbedingungen zustimmen"
								/>
								<div className={cx(Text.base)}>
									Ich habe die{' '}
									<Link to={{ pathname: '/nutzungsbedingungen' }}>Nutzungsbedingungen</Link>{' '}
									vollständig gelesen und stimme ihnen zu.
								</div>
							</label>
						</div>
					</div>
					<Button
						text="Weiter"
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!isNextStepAllowed}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default RegistrationEmailPage;
