import { buildMainLink } from '../../../../services/link';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../../configs/types';
import { Button, H1, P } from '@ard-online/component-styleguide';
import { Mask } from '../../../app/styles';
import { Link, useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { redirectToRefererApp } from '../../../../services/misc';

function ProfileAdded() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	async function onClick() {
		if (appState.navigation.redirectUrl && appState.navigation.entryPageSlug !== PAGE_TYPES.EDIT_ACCOUNT) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.TOKENS_CHANGED, null, true);
		} else {
			navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT));
		}
	}

	/** JSX */
	return (
		<>
			<h1 className={cx(H1.base)}>Profil erstellt</h1>
			<div className={cx(Mask.base)}>
				<p className={cx(P.base)}>Das Profil wurde erfolgreich hinzugefügt.</p>
			</div>
			<Button text="Okay" onClick={onClick} type={BUTTON_TYPES.PRIMARY} as={Link} />
		</>
	);
}

export default ProfileAdded;
