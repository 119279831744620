import { Root } from './styles';
import { Box, Logo } from '../../app/styles';
import { H1, H4, ChannelLogo } from '@ard-online/component-styleguide';
import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';

function NotFoundPage() {
	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<header className={cx(Logo.base)}>
					<ChannelLogo channel="ard" />
				</header>
				<h1 className={cx(H1.base)}>{STRINGS.notFoundPage.headline}</h1>
				<h2 className={cx(H4.base)}>{STRINGS.notFoundPage.kicker}</h2>
			</main>
		</div>
	);
}

export default NotFoundPage;
