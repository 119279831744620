import { css } from '@linaria/core';

export const InfoBox = {
	base: css`
		display: flex;
		justify-content: left;
		align-items: center;
		background: rgba(var(--global-background-secondary));
		box-sizing: border-box;
		border-radius: 8px;
		padding: 10px 10px 10px 0px;
		> svg {
			fill: rgb(var(--global-text)) !important;
			min-width: 50px !important;
			height: 50px !important;
		}
	`,
	text: css`
		text-align: left;
		margin: 0px;
	`,
};
