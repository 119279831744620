import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Box } from '../../app/styles';

export const Root = {
	base: css`
		max-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.${Box.base} {
			margin-left: 0;
			margin-right: 0;
			flex-flow: wrap;
		}
	`,
};

export const LoginButtonWrapper = {
	base: css`
		margin: 20px auto 0 auto;
		display: flex;
		justify-content: center;
		max-width: 270px;
		width: 100%;
		button {
			width: 100%;
			max-width: 270px;
		}
	`,
};

export const Headline = {
	base: css`
		max-width: 750px;
		padding: 0 10px;
		width: 100%;
		@media (max-width: 720px) {
			order: -2;
		}
	`,
};

export const PwForgottenLink = {
	base: css`
		text-align: right;
		float: right;
	`,
};

export const ButtonWrapper = {
	base: css`
		margin: 20px auto 0 auto;
		display: flex;
		justify-content: center;
		max-width: 270px;
		.${ButtonRoot.base} {
			width: 100%;
			color: inherit;
		}
	`,
	zdf: css`
		.${ButtonRoot.base} {
			width: 100%;
			background-color: #fa7d19 !important;
			color: #fff !important;
		}
	`,
};

export const ZdfTermsOfUse = {
	base: css`
		margin: 10px 0 0 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;

		input {
			margin-right: 15px;
			cursor: pointer;
		}
	`,
};
export const Text = {
	base: css`
		font-size: 0.9rem;
		text-align: left;

		a {
			display: inline !important;
			font-weight: 600;
			text-decoration: underline;
			margin-top: 0 !important;
		}
	`,
};
export const CodeInputWrapper = {
	base: css`
		margin-top: 10px;
		input {
			text-transform: uppercase;
			&::placeholder {
				text-transform: none;
			}
		}
	`,
};

export const LoginArea = {
	base: css`
		box-sizing: border-box;
		width: 100%;
	`,
	hasPromoboard: css`
		order: 0;
		margin: 0 auto;
		@media only screen and (min-width: 720px) {
			width: 50%;
		}
	`,
};
