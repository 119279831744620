import { css } from '@linaria/core';

export const Root = {
	base: css`
		background-size: cover;
		padding: 30px 0;
		min-height: 490px;
		box-sizing: border-box;

		@media (min-width: 720px) {
			padding-right: 30px;
			width: 50%;
		}
		@media (max-width: 720px) {
			order: 2;
		}

		ul {
			padding: 0;
		}

		li {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			svg {
				fill: rgb(var(--brand-dark-text-primary));
				width: 48px;
				height: 48px;
				flex-shrink: 0;
			}
		}
		p {
			display: flex;
			align-items: center;
		}
	`,
};

export const Checkmark = {
	base: css`
		width: 24px;
		height: 24px;
		background: #fff;
		z-index: 1;
		position: relative;
		flex-shrink: 0;
		border-radius: 50%;
		pointer-events: none;
		margin-right: 15px;

		svg {
			fill: #83cf92 !important;
			position: absolute;
			top: -18px;
			right: -18px;
			width: 60px !important;
			height: 60px !important;
		}
	`,
};
