const HOST = 'https://accounts-test.ard.de';

const testEnv = {
	STAGE_TYPE: 'test',
	HOST: HOST,
	SSO_CLIENT_HOSTS: [
		HOST,
		'https://sso-test.ardmediathek.de',
		'https://sso-test.ardaudiothek.de',
		'https://sso-test.tagesschau.de',
		'https://sso-test.sportschau.de',
	], // first must be AMS
	FIREBASE: {
		apiKey: 'AIzaSyC3Ybz-vlm6LgDLeai2weqrTAp7eFHXb4A',
		authDomain: 'ardmt-tst.web.app',
		projectId: 'ardmt-tst',
		//messagingSenderId: '128437552391',
		appId: '1:128437552391:web:d5c3cf985b940dbdbff1a2',
	},
	FIREBASE_TOKEN_KEY: 'AIzaSyDLdjUsO_YW3nbtc0GazQjsqJ5ajzq5nQ8',
	TRACKING_PROXY_HOST: 'https://accounts-test.ard.de/t',
	PIANO_ENVIRONMENT_ID: '634417',
};

export default testEnv;
