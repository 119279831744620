import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { cx } from '@linaria/core';
import { Avatars, ErrorMessages, ProfileInfos } from '../../../configs/constants';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { INFO_TYPES, MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import { useTrackPageImpression } from '../../../services/tracking';
import { BackIcon, Button, H1 } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import userService from '../../../services/user/UserService';
import ProfileImageWidget from '../../widgets/profileImage/ProfileImageWidget';
import ProfileInfoWidget from '../../widgets/profileInfo/ProfileInfoWidget';
import ProfileNameWidget from '../../widgets/profileName/ProfileNameWidget';
import { CustomBox, Root } from './styles';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { redirectToRefererApp } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';

enum ProfileEditModes {
	MAIN_MAIN,
	MAIN_SUB,
	SUB_SUB,
}

function getInfoBoxText(editMode: number | null) {
	switch (editMode) {
		case ProfileEditModes.SUB_SUB:
			return ProfileInfos.SUB_USER_INFO;
		case ProfileEditModes.MAIN_MAIN:
			return ProfileInfos.MAIN_USER_INFO;
		case ProfileEditModes.MAIN_SUB:
			return ProfileInfos.SUB_AS_MAIN_USER_INFO;
		default:
			return '';
	}
}

function EditProfilePage() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();
	const { userId } = useParams();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [profileId, setProfileId] = useState<string>('');
	const [profileName, setProfileName] = useState<string | null>(null);
	const [profileImageId, setProfileImageId] = useState(0);
	const [editMode, setEditMode] = useState<number | null>(null);

	const isSubmitAllowed = profileName?.trim() && Avatars?.[profileImageId];

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isSubmitAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				const isProfileNameUnique = userService.isProfileNameUnique(profileName || '', profileId);
				if (isProfileNameUnique) {
					await userService.editUser(profileId, profileImageId, profileName || '');
					navigate(buildInfoLink(INFO_TYPES.PROFILE_CHANGED));
				} else {
					setMessage(ErrorMessages.UNIQUE_PROFILE_NAME);
					setLoading(false);
				}
			} catch (error) {
				console.error(error);
				setMessage(ErrorMessages.DEFAULT);
				setLoading(false);
			}
		}
	}

	function onDeleteSubUser() {
		navigate(buildMainLink(`${PAGE_TYPES.DELETE_PROFILE}/${profileId}`));
	}

	async function onGoBack(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (appState.navigation.redirectUrl && appState.navigation.entryPageSlug !== PAGE_TYPES.EDIT_ACCOUNT) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.BACK_TO_REFERER_APP);
		} else {
			navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT));
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	/** Effect-Hook to set the right profile data */
	useEffect(() => {
		(async () => {
			const currentUserId = userId ?? userService.userId ?? '';
			const currentUser = userService.users.get(currentUserId);
			if (currentUser) {
				const imageIndex = Avatars.indexOf(currentUser.photoURL);
				setProfileId(currentUser.uid);
				setProfileName(currentUser.displayName);
				setProfileImageId(imageIndex === -1 ? 0 : imageIndex);
			}

			if (userService.isSubUser) {
				setEditMode(ProfileEditModes.SUB_SUB);
			} else if (!currentUser?.isSubUser) {
				setEditMode(ProfileEditModes.MAIN_MAIN);
			} else if (currentUser?.isSubUser) {
				setEditMode(ProfileEditModes.MAIN_SUB);
			} else {
				setEditMode(null);
			}
		})();
	}, [userId]);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base, Box.type.medium, CustomBox.base)}>
				{!userService.isSubUser && (
					<div className={cx(BackButtonWrapper.base)}>
						<Button
							text="Zurück"
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							onClick={onGoBack}
							as={Link}
						/>
					</div>
				)}
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<h1 className={cx(H1.base)}>Profil bearbeiten</h1>
					<div className={cx(Mask.base)}>
						<ProfileNameWidget setProfileName={setProfileName} initialValue={profileName} />
						<ProfileImageWidget profileImageId={profileImageId} setProfileImageId={setProfileImageId} />
					</div>
					<ProfileInfoWidget
						text={getInfoBoxText(editMode)}
						onDeleteSubUser={editMode === ProfileEditModes.MAIN_SUB ? onDeleteSubUser : undefined}
					/>
					{message && <MessageWidget>{message}</MessageWidget>}
					<Button
						text="Speichern"
						type={BUTTON_TYPES.PRIMARY}
						onClick={onSubmit}
						isDisabled={!isSubmitAllowed}
						isLoading={loading}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</form>
			</main>
		</div>
	);
}

export default EditProfilePage;
