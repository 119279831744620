import { FormEvent, useState } from 'react';
import { BulletpointIcon, DeleteButton, Root } from './styles';
import { BackButtonWrapper, Box, Form, Mask } from '../../app/styles';
import { BackIcon, Button, ClearIcon, H1, Input, P } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import { Link } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { isGoogleApiError, isTouchDevice } from '../../../services/misc';
import { AnyObject } from '../../../types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

const BULLETPOINTS = [
	'ARD-übergreifendes Konto',
	'Merklisten und Inhalte zum Weiterschauen, -hören und -lesen',
	'Personalisierte Empfehlungen',
	'Altersfreigabe',
	'Synchronisation über alle Geräte',
];

function DeleteAccountPage() {
	/** Variables */
	const email = userService.email;
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState(null);

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (email && password && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				await signInWithEmailAndPassword(auth, email, password); // validate password
				try {
					await userService.deleteMainUser();
				} catch (error) {
					console.error(error);
					setMessage(STRINGS.deleteAccountPage.submit.error.text);
					setLoading(false);
				}
			} catch (error) {
				if (isGoogleApiError(error)) {
					switch (error.code) {
						case GoogleApiCodes.WRONG_PASSWORD:
							setMessage(STRINGS.deleteAccountPage.submit.wrongPassword.text);
							break;

						case GoogleApiCodes.TOO_MANY_REQUESTS:
							setMessage(STRINGS.deleteAccountPage.submit.tooManyRequests.text);
							break;

						default:
							setMessage(ErrorMessages.DEFAULT);
					}
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword(null);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<form className={cx(Form.base)} onSubmit={onSubmit}>
					<div className={cx(BackButtonWrapper.base)}>
						<Button
							text="Zurück"
							type={BUTTON_TYPES.TEXT}
							iconLeft={<BackIcon />}
							href={buildMainLink(PAGE_TYPES.EDIT_ACCOUNT)}
							as={Link}
						/>
					</div>
					<h1 className={cx(H1.base)}>{STRINGS.deleteAccountPage.headline}</h1>
					<div className={cx(Mask.base)}>
						<p className={cx(P.base)}>{STRINGS.deleteAccountPage.text}</p>
						<ul>
							{BULLETPOINTS.map((bulletpoint, index) => (
								<li key={index}>
									<div className={cx(BulletpointIcon.base)}>
										<ClearIcon />
									</div>
									{bulletpoint}
								</li>
							))}
						</ul>
						<p className={cx(P.base)}>
							{STRINGS.deleteAccountPage.password.text}
							{email}.
						</p>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onInputChange}
							htmlAttributes={{
								autoComplete: 'current-password',
								title: 'Passwort eingeben',
							}}
							hasFocus={!isTouchDevice()}
						/>
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<div className={cx(DeleteButton.base)}>
						<Button
							type={BUTTON_TYPES.PRIMARY}
							text="Konto unwiderruflich löschen"
							onClick={onSubmit}
							isDisabled={!(email && password)}
							isLoading={loading}
							htmlAttributes={{
								type: 'submit',
							}}
						/>
					</div>
				</form>
			</main>
		</div>
	);
}

export default DeleteAccountPage;
