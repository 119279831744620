import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const Root = {
	base: css`
		max-height: 100%;

		input {
			margin-top: 11px;
		}
	`,
};

export const ButtonWrapper = {
	base: css`
		.${ButtonRoot.base} {
			width: 100%;
			color: inherit;
			background-color: transparent;
		}
	`,
};
