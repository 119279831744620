import userService from '../user/UserService';

export default async function fetchDeleteUsers(subUserId?: string | undefined) {
	try {
		const idToken = userService.idToken;
		const requestUrl = subUserId ? `/users/${subUserId}` : '/users';
		const res = await fetch(requestUrl, {
			method: 'DELETE',
			headers: {
				'X-Authorization': `Bearer ${idToken}`,
			},
		});

		if (!res.ok) {
			throw new Error(`Error deleting users | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		throw error;
	}
}
