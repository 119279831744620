import { useEffect, useState } from 'react';
import { BackButtonWrapper, Box, Mask } from '../../app/styles';
import { BackIcon, Button, H1, P } from '@ard-online/component-styleguide';
import { Root } from './styles';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import { Link, useNavigate } from 'react-router-dom';
import { redirectToRefererApp } from '../../../services/misc';
import userService from '../../../services/user/UserService';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import type { AnyFunction } from '../../../types';
import MessageWidget from '../../widgets/message/MessageWidget';
import { ErrorMessages } from '../../../configs/constants';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useTrackPageImpression } from '../../../services/tracking';

function AgeVerificationDeletePage() {
	/** Variables */
	const { appState } = useContextActions();
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const navigate = useNavigate();

	async function onClickDelete() {
		setIsLoading(true);

		if (!isLoading) {
			try {
				const idToken = userService.idToken;
				const res = await fetch('/age-verification', {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						idToken,
					}),
				});

				if (res.ok) {
					const idToken = await userService.refreshIdToken();
					await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.TOKENS_CHANGED, idToken);
					navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT));
				} else {
					setIsLoading(false);
					setMessage(ErrorMessages.DEFAULT);
				}
			} catch (error) {
				setIsLoading(false);
				setMessage(ErrorMessages.DEFAULT);
				console.error(error);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!userService.isAgeVerified) {
			navigate(buildMainLink(PAGE_TYPES.EDIT_ACCOUNT), { replace: true });
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<div className={cx(BackButtonWrapper.base)}>
					<Button
						text="Zurück"
						type={BUTTON_TYPES.TEXT}
						iconLeft={<BackIcon />}
						href={buildMainLink(PAGE_TYPES.EDIT_ACCOUNT)}
						as={Link}
					/>
				</div>
				<h1 className={cx(H1.base)}>{STRINGS.ageVerificationDeletePage.revokeAge.headline}</h1>
				<div className={cx(Mask.base)}>
					<p className={cx(P.base)}>{STRINGS.ageVerificationDeletePage.revokeAge.text}</p>
				</div>
				{message && <MessageWidget>{message}</MessageWidget>}
				<Button
					text="Altersfreigabe löschen"
					onClick={onClickDelete}
					isLoading={isLoading}
					type={BUTTON_TYPES.PRIMARY}
				/>
			</main>
		</div>
	);
}

export default AgeVerificationDeletePage;
