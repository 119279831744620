import { FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { Root, ButtonsWrapper } from './styles';
import { Box, Form, Mask } from '../../app/styles';
import { Button, H1, Input, P } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import { ErrorMessages, ProfileInfos, GoogleApiCodes } from '../../../configs/constants';
import { buildMainLink, buildInfoLink } from '../../../services/link';
import { isGoogleApiError, isTouchDevice } from '../../../services/misc';
import { PAGE_TYPES, INFO_TYPES } from '../../../configs/types';
import { AnyObject } from '../../../types';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { cx } from '@linaria/core';

function DeleteProfilePage() {
	/** Variables */
	const email = userService.email;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState(null);

	const { userId } = useParams() as { userId: string };

	async function onDeleteSubUser(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (email && password && userId && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				await signInWithEmailAndPassword(auth, email, password); // validate password
				try {
					await userService.deleteSubUser(userId);
					navigate(buildInfoLink(INFO_TYPES.PROFILE_DELETED));
				} catch (error) {
					console.error(error);
					setLoading(false);
					setMessage(ErrorMessages.DELETE_PROFILE);
				}
			} catch (error) {
				if (isGoogleApiError(error)) {
					switch (error.code) {
						case GoogleApiCodes.WRONG_PASSWORD:
							setMessage(STRINGS.deleteAccountPage.submit.wrongPassword.text);
							break;

						case GoogleApiCodes.TOO_MANY_REQUESTS:
							setMessage(STRINGS.deleteAccountPage.submit.tooManyRequests.text);
							break;

						default:
							setMessage(ErrorMessages.DEFAULT);
					}
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onGoBack() {
		navigate(`${buildMainLink(PAGE_TYPES.EDIT_PROFILE)}/${userId}`);
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword(null);
		}
	}

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<main className={cx(Box.base)}>
				<form className={cx(Form.base)} onSubmit={onDeleteSubUser}>
					<h1 className={cx(H1.base)}>{ProfileInfos.DELETE_PROFILE_HEADLINE}</h1>
					<div className={cx(Mask.base)}>
						<p className={cx(P.base)}>{ProfileInfos.DELETE_PROFILE_INFO}</p>
						<p className={cx(P.base)}>{ProfileInfos.DELETE_PROFILE_INFO_2}</p>
						<p className={cx(P.base)}>
							{STRINGS.deleteAccountPage.password.text}
							{email}.
						</p>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onInputChange}
							htmlAttributes={{
								autoComplete: 'current-password',
								title: 'Passwort eingeben',
							}}
							hasFocus={!isTouchDevice()}
						/>
					</div>
					{message && <MessageWidget>{message}</MessageWidget>}
					<div className={cx(ButtonsWrapper.base)}>
						<Button
							text="Abbrechen"
							type={BUTTON_TYPES.SECONDARY}
							isDisabled={loading}
							onClick={onGoBack}
						/>
						<Button
							text="Ja löschen"
							onClick={onDeleteSubUser}
							type={BUTTON_TYPES.PRIMARY}
							isDisabled={!(email && password && userId)}
							isLoading={loading}
						/>
					</div>
				</form>
			</main>
		</div>
	);
}

export default DeleteProfilePage;
